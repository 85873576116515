import { css } from "@emotion/react";
import SF_PRO_TEXT from "assets/fonts/Niu/SF-Pro-Text-Regular.otf";
import SF_PRO_TEXT_BOLD from "assets/fonts/Niu/SF-Pro-Text-Bold.otf";

const niuStyles = css`
  @font-face {
    font-family: "SF Pro Text";
    src: local("SF Pro Text"), url(${SF_PRO_TEXT}) format("opentype");
  }

  @font-face {
    font-family: "SF Pro Text Bold";
    src: local("SF Pro Text Bold"), url(${SF_PRO_TEXT_BOLD}) format("opentype");
  }

  &.niu {
    * {
      font-family: "SF Pro Text", "SF Pro Text Bold" !important;
    }

    .mobile-home-page,
    .not-login,
    .logo-design {
      padding-top: 0 !important;
    }

    .navbar-home {
      display: none !important;
    }

    .top-tournament-full-width {
      margin-left: 1rem !important;

      .title-md {
        margin-left: 0 !important;
      }

      .swiper-pagination-bullet {
        background-color: #bbbbbb;
      }

      .swiper-pagination-bullet-active {
        background-color: #5000b4;
      }
    }

    .icon-play {
      fill: #5000b4 !important;
    }

    .active-link {
      .link-img {
        fill: #5000b4 !important;
        color: #5000b4 !important;

        path {
          opacity: 1 !important;
          fill: #5000b4 !important;
        }
      }
      .link-label {
        color: #5000b4 !important;
      }
    }

    .browse-game-card {
      .game-name {
        color: #1d0c3b !important;
      }

      .player-numb {
        .arcade-icon {
          fill: #1d0c3b !important;
        }

        .label-count {
          color: #776d89 !important;
        }
      }
    }

    .triangle-bg {
      fill: #f5f5fd !important;
    }

    .my-games-page {
      margin-top: -4.063rem !important;
    }

    .browse-game-item {
      border: none;

      &.triangle {
        margin-top: 12px !important;
      }

      .img-div {
        .img-style {
          align-items: center;
          background-color: #ffffff !important;
          display: flex;
          padding: 0.625rem 0 0.625rem 0.625rem !important;

          .thumbnail {
            border: 1px solid #dce4f3;
            left: auto !important;
            margin: 0 !important;
            position: relative;
            top: auto !important;
          }
        }

        .col-browse {
          .game-2-card-content {
            .subtitle-text {
              color: #1d0c3b !important;
            }

            .star-left {
              .star {
                fill: #5000b4 !important;
                height: 1.25rem !important;
                margin-left: -0.2rem;
                margin-top: -0.125rem;
                width: 1.25rem !important;

                path {
                  fill: #5000b4 !important;
                }
              }
            }
          }

          .row-style {
            .profile-icon,
            .timer-icon {
              fill: #1d0c3b;

              path {
                fill: #1d0c3b;
              }
            }

            .profile-icon {
              height: 1rem !important;
              width: 1rem !important;
            }

            .timer-icon {
              height: 0.75rem !important;
              width: 0.75rem !important;
            }

            .rank-icon,
            .play-icon {
              fill: #5000b4 !important;
            }

            .rank-btn,
            .play-btn {
              box-shadow: none;
            }

            .rank-btn {
              border: 1px solid #f1e5ff;
            }

            .play-btn {
              background: #f1e5ff;
            }
          }
        }
      }
    }

    .modal_header,
    .draggable-modal,
    .leaderboard-wrapper {
      background-color: #fafafa;
    }

    .leaderboard-popup-item {
      p,
      b,
      span {
        color: #1d0c3b !important;
      }

      .close {
        fill: #1d0c3b !important;
      }
    }

    .lower-div {
      .lower-div-item {
        align-items: center;
        border: none !important;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .icon {
          background: #f5f5fd !important;
          box-shadow: none !important;

          svg {
            path {
              fill: #5000b4 !important;
            }
          }
        }

        .lower-div-title {
          color: #776d89 !important;
        }

        .lower-div-footer {
          font-size: 0.75rem;
          font-weight: 400;
          line-height: 0.9rem;
          max-width: 6.25rem;
        }
      }
    }

    .rank-list-card {
      .list-heading {
        padding-bottom: 0;
      }

      .rank-list-container-first,
      .rank-list-container {
        .text-style-transaction,
        .name-style {
          color: #776d89 !important;
        }
      }

      .rank-list-container-first {
        border-radius: 0.75rem !important;
        border: 0.031rem solid #dce4f3;
      }

      .rank-list-container {
        border-radius: 0.75rem !important;
        border: 0.031rem solid #dce4f3;
        border-bottom: 0;
        padding-bottom: 0;

        .list-group-item {
          border: none;
          border-bottom: 0.031rem solid #dce4f3;
        }
      }
    }

    .desktop-play-button-backward {
      bottom: 7rem;
      position: relative;

      .motion-button {
        padding: 0;
        width: 100% !important;

        .desktop-play-footer {
          background: #630bd0 !important;
          border-radius: 1.5rem !important;
          font-size: 1.063rem !important;
          height: 3rem !important;
        }
      }
    }

    .profile-page {
      .top-bar-wrapper {
        .navbar-container {
          min-height: 1.25rem !important;

          .back-button-wrapper {
            display: none !important;

            .go-back-text {
              margin-left: 0.25rem !important;
            }
          }

          .user-profile-customization {
            display: none !important;
          }
        }
      }

      .user-profile-wrapper {
        .mobile {
          align-items: center;
          flex-direction: column !important;
          justify-content: center;

          .text-start {
            margin: 0 !important;

            div {
              margin: 0 !important;
            }

            .fontHeadStyle {
              overflow: visible;
            }
          }

          .avatar-container {
            height: 4rem !important;
            width: 4rem !important;

            .user-profile-customization {
              bottom: 0;
              cursor: pointer;
              position: absolute !important;
              right: 0;

              .support-style {
                background: transparent !important;
                border-radius: 50%;
                padding: 0 !important;
                width: 1.5rem !important;
                height: 1.5rem !important;

                div {
                  padding: 0 !important;

                  .edit-button {
                    align-items: center;
                    background: #ededf5 !important;
                    border-radius: 50%;
                    border: none !important;
                    display: flex;
                    height: 1.5rem !important;
                    justify-content: center;
                    padding: 0 !important;
                    width: 1.5rem !important;

                    .edit-icon {
                      fill: #1d0c3b !important;
                      height: 1rem !important;
                      left: auto !important;
                      margin: 0 !important;
                      position: relative;
                      top: auto !important;
                      width: 1rem !important;
                    }
                  }
                }
              }
            }
          }
        }

        .token-sty {
          display: none !important;
        }
      }

      .section-title {
        @media (width < 30rem) {
          font-size: 1.25rem !important;
          line-height: 1.5rem !important;
        }

        @media (width < 20rem) {
          font-size: 1.125rem !important;
          line-height: 1.35rem !important;
        }

        @media (width < 17.5rem) {
          font-size: 1rem !important;
          line-height: 1.2rem !important;
        }

        @media (width < 16rem) {
          font-size: 0.875rem !important;
          line-height: 1.05rem !important;
        }
      }

      .show-all-text {
        color: #5000b4 !important;
        font-size: 0.813rem !important;
        font-weight: 400 !important;
        line-height: 0.975rem !important;
      }
    }

    .avatar-update-modal {
      .btn-close {
        color: #1d0c3b !important;
        opacity: 1 !important;
      }

      .avatars-container {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        justify-content: center;

        img {
          flex-basis: 20%;
          margin: 0 !important;
        }

        .selected-avatar {
          color: #8e3df2 !important;
          padding: 0.188rem;
        }
      }

      .update-button {
        background: #630bd0;
        border-radius: 1.5rem;
        color: #ffffff;
        float: right;
        margin-top: 1.5rem !important;
        padding: 0.5rem 0.75rem;
      }
    }

    .back-ground {
      background-color: #f5f5fd !important;
      color: #1d0c3b !important;
      height: 46px;
      padding: 1rem 1.25rem;
    }

    .back-button-wrapper {
      .go-back-text {
        margin-left: 0.5rem !important;
      }
    }

    .history-container {
      .white-card {
        box-shadow: 0 0.25rem 0.5rem 0 #1d0c3b08;

        .first-row {
          border-bottom: 0.031rem solid #dce4f3 !important;

          .image-style {
            border: 0.03rem solid #dce4f3;
            border-radius: 0.361rem;
          }

          .txt-style {
            color: #776d89 !important;
          }

          .won-label {
            .dollar {
              color: #776d89;
            }
          }
        }

        .label {
          color: #776d89 !important;
        }

        .label-value {
          font-weight: 400 !important;
        }
      }
    }

    .tournament-info-popup {
      background-color: #fafafa !important;

      .prize-distribution-container {
        color: #1d0c3b;
        font-size: 1.063rem;
        font-weight: 600;
        line-height: 1.275rem;
        margin-top: 3rem;
        padding: 0 1rem;

        .prize-distribution {
          align-items: center;
          background-color: #ffffff;
          border: 0.031rem solid #dce4f3;
          border-radius: 0.75rem;
          color: #1d0c3b;
          display: flex;
          font-size: 0.813rem;
          font-weight: 400;
          justify-content: space-between;
          line-height: 0.975rem;
          margin-top: 1rem;
          padding: 1rem;

          p {
            margin: 0;
          }

          .prize-container {
            align-items: center;
            display: flex;
            gap: 0.2rem;
            justify-content: center;
          }
        }
      }

      .leaderboard-body {
        .header-line {
          color: #1d0c3b !important;
        }

        .white-card-design {
          border: 0.031rem solid #dce4f3;

          .body-md {
            font-size: 0.813rem !important;
          }
        }

        .avatar-row {
          .avatar,
          .avatar1 {
            border: none;
            aspect-ratio: 1/1;
          }

          .avatar {
            height: 5rem !important;
            width: 5rem !important;
          }

          .avatar1 {
            height: 3.5rem !important;
            width: 3.5rem !important;
          }

          .competitor-name {
            color: #1d0c3b !important;
          }

          .competitor-score,
          .own-score {
            color: #776d89 !important;
          }
        }

        .rank-list-container-first,
        .rank-list-container {
          background: #fafafa !important;
          box-shadow: none !important;

          .list-heading {
            background: #fafafa !important;

            div {
              color: #1d0c3b !important;
            }

            .name-style {
              padding-left: 3.438rem !important;
            }
          }

          .list-group-item {
            background-color: #ffffff !important;
            font-weight: 400;
            height: 3.5rem;

            .text-style-transaction {
              font-size: 0.813rem !important;
              color: #776d89 !important;
            }

            .align-items-center {
              height: 100%;
            }
          }
        }

        .rank-list-container-first {
          .list-group-item {
            border: 0.031rem solid #dce4f3;
            border-radius: 0.75rem !important;
          }
        }

        .rank-list-container {
          border: 0.031rem solid #dce4f3;
          border-radius: 0.75rem !important;

          .list-group-item {
            border-bottom: 0.031rem solid #dce4f3;
          }
        }
      }
    }

    .progress-share-overlay {
      .half-style {
        background: #fafafa !important;
        margin-bottom: 0.75rem !important;

        .text-center {
          .latest-score-label {
            color: #776d89 !important;
          }

          .latest-score {
            color: #5000b4 !important;
          }
        }

        .player-rank-board {
          .board-container {
            .tile {
              fill: #ffffff;
            }

            .label-style {
              background-color: #ffffff;
            }

            .score-body {
              .name-style:first-of-type {
                color: #1d0c3b !important;
              }

              .name-style,
              .score {
                font-weight: 400 !important;
                color: #776d89 !important;
              }
            }

            .middle-board {
              .own-avatar-img {
                aspect-ratio: 1/1;
                height: 3.75rem !important;
                width: 3.75rem !important;
              }
            }
          }
        }

        .second-half {
          .btn-container {
            background: #fafafa !important;
            border: 1px solid #dddddd !important;
            box-shadow: none !important;

            svg {
              fill: #1d0c3b !important;
              height: 1.25rem;
              width: 1.25rem;
            }
          }

          .btn-replay {
            background: #630bd0 !important;
            border-radius: 1.5rem;
            color: #ffffff;
            font-size: 1.063rem;
            font-weight: 400;
            height: 3rem;
            line-height: 1.275rem;
            width: 10.938rem;
            margin: 0 !important;
          }
        }
      }
    }

    .recommended-games-container {
      top: 0rem !important;

      .recommended-games {
        background-color: #fafafa !important;
        padding-top: 0.5rem !important;

        .header-style {
          padding-left: 1rem !important;
        }

        .games-container {
          padding: 1.5rem 0 0 !important;
          margin: 0 !important;
        }
      }
    }

    .browse-popup {
      background-color: #fafafa;

      .gamename-style,
      .title-md {
        color: #1d0c3b;
      }

      .small-card {
        border: 0.031rem solid #dce4f3;
        border-radius: 0.75rem;
      }

      .rank-btn {
        border: 1px solid #f1e5ff;
        box-shadow: none;
      }

      .play-btn-browse {
        background: #630bd0;
        color: #ffffff;
        font-size: 1rem;
      }
    }

    .footer-content {
      align-items: center;
      display: flex;
      height: 5rem;
    }

    .prizes-slider-item {
      .upper-div {
        border-color: #dce4f3;
      }
    }

    .profile-history-wrapper {
      box-shadow: rgba(0, 0, 0, 0.05) 0 0.156rem 0.313rem 0;

      .history-border {
        .list-group-item {
          border-bottom: 0.031rem solid #dce4f3;
          border-radius: 0 !important;

          .rank-list-avatar {
            box-shadow: none !important;
          }
        }
      }
    }

    .progress-bar-container {
      .progress-bar {
        background-color: #5000b4 !important;
        box-shadow: none;
      }
    }

    .tournament-info-list {
      .header-line {
        color: #1d0c3b;
      }

      .white-card-design {
        box-shadow: none;

        .rank-style {
          align-items: center;
          height: 3.5rem;

          span {
            color: #1d0c3b;
            font-size: 0.813rem;
            font-weight: 400;
            line-height: 0.975rem;
          }
        }
      }
    }

    .top-tournament-card {
      .clock-participant-container {
        .clock-participant-bg-svg {
          display: none;
        }

        .clock-participant-text {
          color: #776d89;

          svg {
            path {
              fill: #1d0c3b;
            }
          }
        }
      }
    }

    .survey-card {
      margin: 1.25rem 1rem 0;
    }

    .leaderboard-container {
      .prizes-slider-container {
        border: none;
        box-shadow: 0 0.25rem 0.5rem 0 #1d0c3b08;
      }
    }

    .draggable-modal {
      [data-rsbs-overlay] {
        margin: 0;
        max-width: 100vw;
      }
    }
  }
`;

export default niuStyles;
