import React, { useEffect } from "react";
import useModalHandler from "hooks/useModalHandler";
import { useAppDispatch, useAppSelector } from "redux/Store";
import { useLocation } from "react-router";
import { fetchSubscriptionPackageInfo, fetchSubscriptionUserInfo } from "redux/reducers/subscriptionReducer";
import GoamaSubscriptionPaymentService from "services/payment/goama/GoamaSubscriptionPaymentService";
import SubscriptionPackageModal from ".";
import PaymentFailedModal from "./PaymentFailedModal";
import PaymentSuccessfulModal from "./PaymentSuccessfulModal";
import UnsubscribedSuccessfulModal from "./UnsubscribedSuccessfulModal";
import UnsubscribeModal from "./UnsubscribeModal";

export default function SubscriptionModalProvider() {
  const dispatch = useAppDispatch();
  const { search: queryParams } = useLocation();
  const { application } = useAppSelector((reduxState: any) => reduxState.common);
  const { closeUnsubscribeModal, openUnsubscribeSuccessModal } = useModalHandler();

  const {
    showSubscriptionModal,
    showPaymentSuccessModal,
    showPaymentFailedModal,
    showUnsubscribeSuccessModal,
    showUnsubscribeModal,
  } = useAppSelector((state: any) => state.modal);

  const { openSubscriptionModal } = useModalHandler();

  useEffect(() => {
    dispatch(fetchSubscriptionPackageInfo() as any)
      .then((response: any) => {
        // Check if packageInfo exists before dispatching an action to fetch subscription user info.
        if (response?.slug) {
          // Fetch subscription user info based on the slug from the packageInfo.
          dispatch(fetchSubscriptionUserInfo(response?.slug));
        }
      })
      .catch((error: any) => {
        // Handle errors if any
        console.error("Error fetching subscription package info:", error);
      });

    // eslint-disable-next-line
  }, [application?.enable_subscription]);

  useEffect(() => {
    if (queryParams.includes("unsubscribe=true")) {
      const unsubscribe: any = new GoamaSubscriptionPaymentService();
      unsubscribe
        .UnsubscribeService()
        .then((res: any) => {
          if (res?.status === "success") {
            openUnsubscribeSuccessModal();
            closeUnsubscribeModal();
          }
        })
        .catch((e: any) => {
          console.log({ e });
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams, application?.enable_subscription]);

  useEffect(() => {
    setTimeout(() => {
      if (queryParams.includes("subscriptionmodal=true")) {
        openSubscriptionModal();
      }
    }, 1000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  return (
    <>
      {showSubscriptionModal && <SubscriptionPackageModal />}
      {showPaymentSuccessModal && <PaymentSuccessfulModal />}
      {showPaymentFailedModal && <PaymentFailedModal />}
      {showUnsubscribeModal && <UnsubscribeModal />}
      {showUnsubscribeSuccessModal && <UnsubscribedSuccessfulModal />}
    </>
  );
}
