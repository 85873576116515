import React, { useState, useEffect } from "react";
import { css, useTheme } from "@emotion/react";

import { Trans } from "react-i18next";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useAppDispatch, useAppSelector } from "redux/Store";

import analytics from "utils/analytics";
import { fetchUserTournaments } from "actions";
import PaymentPoller from "utils/paymentPoller";
import usePlayNowButton from "hooks/usePlayNowButton";
import { fetchTokenBalance, fetchTokenHistory } from "redux/reducers/tokenReducer";
import { changeMiniProgramPaymentVerifyModalStatus, IUIState } from "redux/reducers/uiReducer";

import LoadingIndicator from "components/common/LoadingIndicator/LoadingIndicator";
import { CommonModal } from "../Modals/CommonModal";
import { APP_SLUGS } from "constants/constants";
import { isBkash, isDana } from "utils/applicationSlug";
import useTokenAddButton from "hooks/useTokenAddButton";

export const PaymentErrorModal = ({ onRetry, ...domProps }: any) => {
  return (
    <>
      <CommonModal
        header={<Trans i18nKey="error">Error</Trans>}
        footer={
          <div>
            <Button css={buttonStyle} onClick={onRetry}>
              <Trans i18nKey="try-again">Try Again</Trans>
            </Button>
            <Button css={buttonStyle} onClick={domProps.onHide}>
              <Trans i18nKey="cancel">Cancel</Trans>
            </Button>
          </div>
        }
        {...domProps}
      >
        <span>
          <Trans i18nKey="something-went-">Something Went wrong!</Trans>
        </span>
      </CommonModal>
      {/* <Modal {...domProps} centered css={errorStyle}>
        <Modal.Header closeButton className="header" css={backgroundStyle}>
          <b>
            <Trans i18nKey="error">Error</Trans>
          </b>
        </Modal.Header>
        <Modal.Body className="text-center body-style" css={backgroundStyle}>
          <p>
            <Trans i18nKey="something-went-">Something Went wrong!</Trans>
          </p>
        </Modal.Body>
        <Modal.Footer
          className="footer-style"
          css={backgroundStyle}
          style={{ borderTop: "none" }}
        >
          <Button css={buttonStyle} onClick={onRetry}>
            <Trans i18nKey="try-again">Try Again</Trans>
          </Button>
          <Button css={buttonStyle} onClick={domProps.onHide}>
            <Trans i18nKey="cancel">Cancel</Trans>
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
};

export const OldPaymentModals = ({
  amount,
  partialUpdateModals,
  tournament,
  game,
  onHide,
  tokenConfig = null,
  ...domProps
}: any) => {
  const theme: ITheme = useTheme();

  const { buttonLoading: buttonTokenLoading, onPaymentConfirmationClick: onTokenPaymentConfirmationClick } =
    useTokenAddButton(tokenConfig, {
      handlePayment: {
        onSuccess: () => {
          partialUpdateModals({ payment: false, verification: true });
        },
        onError: () => partialUpdateModals({ paymentError: true, payment: false }),
      },
    });

  const { buttonLoading, onPaymentConfirmationClick } = usePlayNowButton(tournament, game, {
    handlePayment: {
      onSuccess: (showVerifyModal: boolean) =>
        partialUpdateModals({
          payment: false,
          verification: showVerifyModal,
        }),

      onError: () => partialUpdateModals({ paymentError: true, payment: false }),
    },
  });

  const renderConfirmContent = () => {
    let content;

    if (buttonLoading || buttonTokenLoading) {
      content = <LoadingIndicator size={1.4} color={theme.button.buttonTextColor} />;
    } else if (isDana) {
      content = <Trans i18nKey="confirm">CONFIRM</Trans>;
    } else {
      content = <Trans i18nKey="confirm">Confirm</Trans>;
    }
    return content;
  };

  return (
    <>
      <CommonModal
        loading={buttonLoading || buttonTokenLoading}
        onHide={onHide}
        header={<Trans i18nKey="payment-confirm">Payment Confirmation</Trans>}
        footer={
          <div>
            <Button
              disabled={buttonLoading || buttonTokenLoading}
              css={buttonStyle}
              className="payment-btn cancel-btn me-2"
              onClick={onHide}
            >
              {isDana ? <Trans i18nKey="cancel">CANCEL</Trans> : <Trans i18nKey="cancel">Cancel</Trans>}
            </Button>
            <Button
              disabled={buttonLoading || buttonTokenLoading}
              onClick={e => {
                console.log({ tokenConfig });
                if (tokenConfig) {
                  onTokenPaymentConfirmationClick(e);
                  return;
                }
                onPaymentConfirmationClick(e);
                setTimeout(() => {
                  isBkash && onHide();
                }, 5000);
              }}
              css={buttonStyle}
              className="payment-btn ms-2"
            >
              {renderConfirmContent()}
            </Button>
          </div>
        }
        {...domProps}
      >
        <span className="d-flex" style={{ fontSize: "17px" }}>
          <Trans i18nKey="are-you-sure-yo">Are you sure you want to pay</Trans>
          &nbsp;<b>{amount}</b>?
        </span>
      </CommonModal>
      {/* <Modal
        {...domProps}
        onHide={onHide}
        backdrop="static"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="header" css={backgroundStyle}>
          <b>
            <Trans i18nKey="payment-confirm">Payment Confirmation</Trans>
          </b>
        </Modal.Header>
        <Modal.Body className="body" css={backgroundStyle}>
          <span>
            <Trans i18nKey="are-you-sure-yo">
              Are you sure you want to pay <b>{amount}</b>?
            </Trans>
          </span>
        </Modal.Body>
        <Modal.Footer
          className="footer"
          style={{ borderTop: "none" }}
          css={backgroundStyle}
        >
          <Button
            css={buttonStyle}
            className="payment-btn cancel-btn me-2"
            onClick={onHide}
          >
            <Trans i18nKey="cancel">Cancel</Trans>
          </Button>
          <Button
            disabled={buttonLoading}
            onClick={onPaymentConfirmationClick}
            css={buttonStyle}
            className="payment-btn ms-2"
          >
            {buttonLoading ? (
              <LoadingIndicator
                size={1.4}
                color={theme.button.buttonTextColor}
              />
            ) : (
              <Trans i18nKey="confirm-(button">Confirm</Trans>
            )}
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
};

export function VerifyPaymentModal({ tournament, show, onHide, participant, ...domProps }: any) {
  const dispatch = useAppDispatch();
  const { application, country } = useAppSelector((state: any) => state.common);
  const [modalState, setModalState] = useState<"checking" | "cancelled" | "failed" | "error" | "success">(
    participant ? "success" : "checking",
  );
  const [message, setMessage] = useState("");
  const referenceCode = (localStorage && localStorage.getItem(`active-ref-${tournament?.id}`)) ?? "";

  function closeModal() {
    onHide();
    dispatch(fetchUserTournaments(application?.slug, country));
  }

  function handleClick() {
    onHide();
    dispatch(fetchTokenBalance(country));
    dispatch(fetchUserTournaments(application?.slug, country));
  }

  function verifyPayment(referenceCode: string) {
    const poller = new PaymentPoller(5, 6000);
    poller.pollPaymentStatus(referenceCode, (success, status, failedReason) => {
      if (success) {
        analytics.paymentCompleted(tournament.entry_fee);
        analytics.logRevenue(tournament?.id, tournament?.entry_fee, application?.payment_channel?.currency);
        setModalState("success");
      } else if (status === "cancel") {
        setModalState("cancelled");
        if (failedReason) setMessage(failedReason);
      } else if (status === "failed") {
        setModalState("failed");
        if (failedReason) setMessage(failedReason);
      } else {
        setModalState("error");
      }
    });
  }

  useEffect(() => {
    if (modalState === "checking" && show && referenceCode) {
      verifyPayment(referenceCode);
    }
    // eslint-disable-next-line
  }, [modalState, referenceCode, show]);

  function getModalTitle() {
    switch (modalState) {
      case "failed":
      case "error":
        return <Trans i18nKey="payment-verific">Payment Verification Failed!</Trans>;
      case "cancelled":
        return <Trans i18nKey="payment-cancell">Payment Cancelled</Trans>;
      case "success":
        return <Trans i18nKey="payment-success">Payment Successful!</Trans>;
      default:
        return <Trans i18nKey="verifying-your-">Verifying Payment</Trans>;
    }
  }

  function getModalBody() {
    const commonErrorMessage = (
      <Trans i18nKey="your-payment-co">Your Payment could not be verified. Please try again!</Trans>
    );
    switch (modalState) {
      case "error":
        return commonErrorMessage;
      case "failed":
        return (
          (application?.slug === APP_SLUGS.FOODPANDA_APP && (
            <Trans i18nKey="does-not-have-e">You have used up all your points. Earn more points to play!</Trans>
          )) ||
          (message && <Trans i18nKey="does-not-have-e">{message}</Trans>) ||
          commonErrorMessage
        );
      case "cancelled":
        return message || <Trans i18nKey="your-payment-ca">Your Payment was cancelled.</Trans>;
      case "success":
        return <Trans i18nKey="your-payment-su">Your payment was successful.</Trans>;
      default:
        return <Trans i18nKey="verifying-your-">Verifying your payment. Please Wait.</Trans>;
    }
  }

  return (
    <>
      <CommonModal
        loading={modalState === "checking"}
        show={show}
        onHide={closeModal}
        header={<b>{getModalTitle()}</b>}
        footer={
          <div>
            <Button
              css={buttonStyle}
              className="payment-btn cancel-btn me-2"
              onClick={handleClick}
              disabled={modalState === "checking"}
            >
              {modalState === "success" ? <Trans i18nKey="okay">Okay</Trans> : <Trans i18nKey="cancel">Cancel</Trans>}
            </Button>
            {modalState !== "cancelled" && modalState !== "failed" && modalState !== "success" && (
              <Button
                onClick={() => {
                  setModalState("checking");
                  verifyPayment(referenceCode);
                }}
                css={buttonStyle}
                disabled={modalState === "checking"}
              >
                {modalState === "checking" && <LoadingIndicator size={1.4} color="#fff" />}
                {modalState === "error" && <Trans i18nKey="try-again">Try Again</Trans>}
              </Button>
            )}
          </div>
        }
        {...domProps}
      >
        <div>
          <span>{getModalBody()}</span>
        </div>
      </CommonModal>
      {/* <Modal
        show={show}
        backdrop="static"
        size="lg"
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        {...domProps}
      >
        <Modal.Header
          closeButton={modalState !== "checking"}
          className="header"
          css={backgroundStyle}
        >
          <b>{getModalTitle()}</b>
        </Modal.Header>
        <Modal.Body className="body" css={backgroundStyle}>
          <div className="text-center">
            <p>{getModalBody()}</p>
          </div>
        </Modal.Body>
        <Modal.Footer
          className="footer"
          style={{ borderTop: "none" }}
          css={backgroundStyle}
        >
          <Button
            css={buttonStyle}
            className="payment-btn cancel-btn me-2"
            onClick={handleClick}
            disabled={modalState === "checking"}
          >
            {modalState === "success" ? (
              <Trans i18nKey="okay">Okay</Trans>
            ) : (
              <Trans i18nKey="cancel">Cancel</Trans>
            )}
          </Button>
          {modalState !== "cancelled" &&
            modalState !== "failed" &&
            modalState !== "success" && (
              <Button
                onClick={() => {
                  setModalState("checking");
                  verifyPayment(referenceCode);
                }}
                css={buttonStyle}
                disabled={modalState === "checking"}
              >
                {modalState === "checking" && (
                  <LoadingIndicator size={1.4} color="#fff" />
                )}
                {modalState === "error" && (
                  <Trans i18nKey="try-again">Try Again</Trans>
                )}
              </Button>
            )}
        </Modal.Footer>
      </Modal> */}
    </>
  );
}

export function MiniProgramVerifyPaymentModal({ tournament, show, onHide, tokenConfig = null, ...domProps }: any) {
  const dispatch = useAppDispatch();
  const { application, country } = useAppSelector((state: any) => state.common);
  const { miniAppPaymentVerifyModalStatus: status }: IUIState = useAppSelector((state: any) => state.ui);
  const [message, setMessage] = useState("");
  let referenceCode = (localStorage && localStorage.getItem(`active-ref-${tournament?.id}`)) ?? "";
  if (tokenConfig) {
    referenceCode = (localStorage && localStorage.getItem(`token-buy-ref`)) ?? "";
  }

  function closeModal() {
    onHide();
    dispatch(fetchUserTournaments(application?.slug, country));
  }

  function handleClick() {
    onHide();
    dispatch(fetchTokenBalance(country));
    dispatch(fetchUserTournaments(application?.slug, country));
    if (tokenConfig) {
      dispatch(fetchTokenHistory(country));
    }
  }

  function verifyPayment(referenceCode: string) {
    const poller = new PaymentPoller(2, 2000);
    poller.pollPaymentStatus(referenceCode, (success, status, failedReason) => {
      if (success) {
        if (!tokenConfig) {
          analytics.paymentCompleted(tournament?.entry_fee);
          analytics.logRevenue(
            tournament?.id,
            tournament?.entry_fee,
            application?.payment_channel?.currency || "Unknown",
          );
        }
        dispatch(changeMiniProgramPaymentVerifyModalStatus("success"));
      } else if (status === "cancel") {
        dispatch(changeMiniProgramPaymentVerifyModalStatus("cancelled"));
        if (failedReason) setMessage(failedReason);
      } else if (status === "failed") {
        dispatch(changeMiniProgramPaymentVerifyModalStatus("failed"));
        if (failedReason) setMessage(failedReason);
      } else {
        dispatch(changeMiniProgramPaymentVerifyModalStatus("error"));
      }
    });
  }

  useEffect(() => {
    if (status === "checking" && show && referenceCode) verifyPayment(referenceCode);
    // eslint-disable-next-line
  }, [status, referenceCode, show]);

  useEffect(() => {
    return () => {
      dispatch(changeMiniProgramPaymentVerifyModalStatus("waiting"));
    };
    // eslint-disable-next-line
  }, []);

  function getModalTitle() {
    switch (status) {
      case "failed":
      case "error":
        return <Trans i18nKey="payment-verific">Payment Verification Failed!</Trans>;
      case "cancelling":
        return <Trans i18nKey="cancelling-paym">Cancelling Payment</Trans>;
      case "cancelled":
        return <Trans i18nKey="payment-cancell">Payment Cancelled</Trans>;
      case "success":
        return <Trans i18nKey="payment-success">Payment Successful!</Trans>;
      case "waiting":
        return <Trans i18nKey="waiting">Waiting</Trans>;
      default:
        return <Trans i18nKey="verifying-your-">Verifying Payment</Trans>;
    }
  }

  function getModalBody() {
    const commonErrorMessage = (
      <Trans i18nKey="your-payment-co">Your Payment could not be verified. Please try again!</Trans>
    );
    switch (status) {
      case "error":
        return commonErrorMessage;
      case "failed":
        return message || commonErrorMessage;
      case "cancelling":
        return <Trans i18nKey="cancelling-your">Cancelling your payment. Please Wait.</Trans>;
      case "cancelled":
        return message || <Trans i18nKey="your-payment-caa">Your Payment was cancelled.</Trans>;
      case "success":
        return <Trans i18nKey="your-payment-su">Your payment was successful.</Trans>;
      case "waiting":
        return <Trans i18nKey="waiting-for-pay">Waiting for Payment</Trans>;
      default:
        return <Trans i18nKey="verifying-your-">Verifying your payment. Please Wait.</Trans>;
    }
  }

  return (
    <Modal
      show={show}
      backdrop="static"
      size="lg"
      onHide={closeModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      {...domProps}
    >
      <Modal.Header closeButton={status !== "checking"} className="header" css={backgroundStyle}>
        <b>{getModalTitle()}</b>
      </Modal.Header>

      <Modal.Body className="body" css={backgroundStyle}>
        <div className="text-center">
          <p>{getModalBody()}</p>
        </div>
      </Modal.Body>

      <Modal.Footer className="footer" style={{ borderTop: "none" }} css={backgroundStyle}>
        {["checking", "error"].includes(status) && (
          <Button
            onClick={() => {
              dispatch(changeMiniProgramPaymentVerifyModalStatus("checking"));
              verifyPayment(referenceCode);
            }}
            css={buttonStyle}
            disabled={status === "checking"}
          >
            {status === "checking" && <LoadingIndicator size={1.4} color="#fff" />}
            {status === "error" && <Trans i18nKey="try-again">Try Again</Trans>}
          </Button>
        )}

        {!["waiting", "cancelling", "checking"].includes(status) && (
          <Button css={buttonStyle} onClick={handleClick} disabled={status === "checking"}>
            {status === "success" ? <Trans i18nKey="okay">Okay</Trans> : <Trans i18nKey="cancel">Cancel</Trans>}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export const buttonStyle = (theme: ITheme) => css`
  border: none;
  color: white;
  font-size: 13px;
  cursor: pointer;
  padding: 8px 20px;
  text-align: center;
  border-radius: 10px;
  display: inline-block;
  text-decoration: none;
  background: linear-gradient(
    270deg,
    ${theme.button.primaryGradientStart} 0%,
    ${theme.button.primaryGradientStop} 122.67%
  );

  &:hover {
    text-decoration: none;
    color: white;
  }
`;

/* const errorStyle = (theme: ITheme) => css`
  color: ${theme.text.primary};
`; */

const backgroundStyle = (theme: ITheme) => css`
  color: ${theme.text.primary};
  background-color: ${theme.background.primary};

  &.header {
    border: none;

    button.close {
      color: ${theme.text.primary};
      text-shadow: none;
    }
  }

  &.modal-body {
    padding: 1rem 1.5rem;
    font-size: 16px !important;
  }

  &.modal-header {
    padding: 1.5rem 1.5rem;
    font-size: 18px !important;
  }

  &.modal-footer {
    justify-content: space-around !important;
  }
`;
