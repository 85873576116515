import type { AxiosInstance } from "axios";
import { APP_SLUGS } from "constants/constants";
import { decodeDataFromURL, isStringEqual } from "utils";

const BASE_URL = "go-games.gg";
const BUBBALOO_BASE_URL = "bubbaloocity.com.mx";

export const isInProductionMode = () => import.meta.env.PROD;
export const isInDevelopmentMode = () => import.meta.env.DEV;

const removeMyanmarPrefix = (url: string): string => {
  const isFromMyanmar = url.includes(".mm");

  if (isFromMyanmar) url = url.replaceAll(".mm", "");

  return url;
};

const getApiUrl = (prefix: string, baseUrl: string = BASE_URL): string => {
  let url = `https://${prefix}.`;

  if (isInDevelopmentMode()) {
    url += `dev.${baseUrl}`;
  } else if (window.location.host.includes(baseUrl)) {
    url += window.location.host;
  } else if (window.location.host.includes(BUBBALOO_BASE_URL)) {
    url += BUBBALOO_BASE_URL;
  } else {
    url += baseUrl;
  }

  url = removeMyanmarPrefix(url);

  return url;
};

export const getUserServiceBaseUrl = () => getApiUrl("api");

export const getRealTimeServiceBaseUrl = () => getApiUrl("rts");

export const getAdServiceBaseUrl = () => getApiUrl("ads");

export const getAppSlugHeaderForAxios = () => {
  if (isInDevelopmentMode()) return "";

  const { app_slug } = decodeDataFromURL();

  let appSlug = app_slug;

  if (isStringEqual(app_slug || "", APP_SLUGS.GCASH_ALIPLUS)) {
    appSlug = APP_SLUGS.GCASH;
  }

  return { "Goama-App": appSlug ?? "" };
};

export const isTokenExp = (): boolean => {
  const eqADay = 24 * 60 * 60 * 1000;
  const lastSaved = localStorage.getItem("session-exp") ?? "0";
  const currentTime = new Date();
  const diff = Number(currentTime.valueOf()) - parseInt(lastSaved);
  return diff >= eqADay;
};

export const updateAxiosInstanceAPIToken = (instances: AxiosInstance[], token: string) => {
  let authToken = "";

  if (localStorage && !isTokenExp()) authToken = localStorage.getItem("session-token") ?? "";

  instances.map(instance => {
    instance.defaults.headers.common["Authorization"] = `Bearer ${authToken || token}`;

    return instance;
  });
};

export const checkSessionAndUpdateAxiosInstance = (instance: AxiosInstance) => {
  const previousToken = sessionStorage?.getItem("session-token") ?? sessionStorage?.getItem("init-token");

  if (previousToken && !instance.defaults.headers.common?.Authorization)
    instance.defaults.headers.common.Authorization = `Bearer ${previousToken}`;
};

export const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
