import React from "react";
import { isBarca, isBubbaloo, isClaro, isCroydon, isGoogly, isNiu, isPepsico } from "utils/applicationSlug";
import { ReactComponent as Home } from "assets/images/Home.svg";
import { ReactComponent as SidebarHome } from "assets/desktop/icons/Browse.svg";
import { ReactComponent as ClaroHome } from "assets/clients/Claro/Home.svg";
import { ReactComponent as CroydonHome } from "assets/images/Croydon/Home.svg";
import { ReactComponent as GooglyHome } from "assets/images/googly/Home.svg";
import { ReactComponent as PepsicoHome } from "assets/images/pepsico/Home.svg";
import { ReactComponent as MyGames } from "assets/images/gaming.svg";
import { ReactComponent as SidebarMyGames } from "assets/desktop/icons/MyGames.svg";
import { ReactComponent as ClaroMyGames } from "assets/clients/Claro/MyGames.svg";
import { ReactComponent as CroydonMyGames } from "assets/images/Croydon/MyGames.svg";
import { ReactComponent as GooglyMyGames } from "assets/images/googly/MyGames.svg";
import { ReactComponent as NiuMyGames } from "assets/images/Niu/MyGames.svg";
import { ReactComponent as MyHeroes } from "assets/images/crown.svg";
import { ReactComponent as BubbalooMyHeroes } from "assets/images/bubbaloo/MyHeroes.svg";
import { ReactComponent as PepsicoMyHeroes } from "assets/images/pepsico/MyHeroes.svg";
import { ReactComponent as Arcade } from "assets/images/Play-Games.svg";
import { ReactComponent as SidebarArcade } from "assets/desktop/icons/Arcade.svg";
import { ReactComponent as Missions } from "assets/images/missions/MissionIcon.svg";
import { ReactComponent as PepsicoMissions } from "assets/images/pepsico/Missions.svg";
import { ReactComponent as User } from "assets/images/icon-user.svg";
import { ReactComponent as Player } from "assets/images/icon-player2.svg";
import { AiOutlineUser as BarcaUser, AiOutlineClockCircle as BarcaClock } from "react-icons/ai";
import { ReactComponent as ClaroUser } from "assets/clients/Claro/User.svg";
import { ReactComponent as NiuUser } from "assets/images/Niu/User.svg";
import { ReactComponent as Clock } from "assets/images/icon-clock2.svg";
import { ReactComponent as ClaroClock } from "assets/clients/Claro/Clock.svg";
import { ReactComponent as NiuClock } from "assets/images/Niu/Clock.svg";

interface IconProps {
  className?: string;
  fill?: string;
  iconFromParams?: string;
  isPlayer?: boolean;
  isSidebar?: boolean;
  size?: number;
}

export const HomeIcon = ({ className, iconFromParams, isSidebar }: IconProps) => {
  const IconComponent =
    iconFromParams ||
    (isClaro && ClaroHome) ||
    (isCroydon && CroydonHome) ||
    (isGoogly && GooglyHome) ||
    (isPepsico && PepsicoHome) ||
    (isSidebar && SidebarHome) ||
    Home;

  return <IconComponent className={className} />;
};

export const MyGamesIcon = ({ className, iconFromParams, isSidebar }: IconProps) => {
  const IconComponent =
    iconFromParams ||
    (isClaro && ClaroMyGames) ||
    (isCroydon && CroydonMyGames) ||
    (isGoogly && GooglyMyGames) ||
    (isNiu && NiuMyGames) ||
    (isSidebar && SidebarMyGames) ||
    MyGames;

  return <IconComponent className={className} />;
};

export const MyHeroesIcon = ({ className, iconFromParams }: IconProps) => {
  const IconComponent =
    iconFromParams || (isBubbaloo && BubbalooMyHeroes) || (isPepsico && PepsicoMyHeroes) || MyHeroes;

  return <IconComponent className={className} />;
};

export const ArcadeIcon = ({ className, iconFromParams, isSidebar }: IconProps) => {
  const IconComponent = iconFromParams || (isSidebar && SidebarArcade) || Arcade;

  return <IconComponent className={className} />;
};

export const MissionsIcon = ({ className, iconFromParams }: IconProps) => {
  const IconComponent = iconFromParams || (isPepsico && PepsicoMissions) || Missions;

  return <IconComponent className={className} />;
};

export const UserIcon = ({ className, fill, isPlayer, size }: IconProps) => {
  const IconComponent =
    (isPlayer && Player) || (isBarca && BarcaUser) || (isClaro && ClaroUser) || (isNiu && NiuUser) || User;

  return <IconComponent className={className} fill={fill} height={size} width={size} />;
};

export const ClockIcon = ({ className, size }: IconProps) => {
  const IconComponent = (isBarca && BarcaClock) || (isClaro && ClaroClock) || (isNiu && NiuClock) || Clock;

  return <IconComponent className={className} height={size} width={size} />;
};
