import tournamentRankingReducer from "./tournamentRankingReducer";
import historyReducer from "./historyReducer";
import activeTournamentsReducer from "./activeTournamentsReducer";
import commonReducer from "./commonReducer";
import userTournamentsReducer from "./userTournamentsReducer";
import advertisementsReducer from "./advertisementsReducer";
import tournamentRewardsReducer from "./tournamentRewardsReducer";
import voucherCodeRewardsReducer from "./voucherCodeRewardsReducer";
import uiReducer from "./uiReducer";
import authUserLoginReducers from "./authUserLoginReducers";
import authLinkKey from "./authLinkKey";
import statusPointReducer from "./statusPointReducer";
import socialAuthStatusReducer from "./socialAuthStatusReducer";
import tokenReducer from "./tokenReducer";
import layoutReducer from "./layoutReducer";
import statsReducer from "./statsReducer";
import userAuthenticationReducer from "./userAuthenticationReducer";
import userLoginReducer from "./userLoginReducer";
import prizeRedemptionReducer from "./prizeRedemptionReducer";
import arcadeGameReducer from "./arcadeGameReducer";
import missionReducer from "./missionReducer";
import achievementReducer from "./achievementReducer";
import tournamentPrizesReducer from "./tournamentPrizesReducer";
import transactionsReducer from "./transactionsReducer";
import userFriendListReducer from "./userFriendListReducer";
import notificationReducer from "./notificationReducer";
import sdkReducer, { ISdkState } from "./sdkReducer";
import modalReducer from "./modalReducer";
import scoreReducer from "./scoreReducer";
import teamReducer from "./teamReducer";
import gameplayReducer from "./gameplayReducer";
import appleSigninReducer from "./appleSigninReducer";
import monopolyReducer from "./monopolyReducer";
import gamesOfTheWeekReducer from "./gamesOfTheWeekReducer";
import partnerInfoBalanceReducer from "./partnerInfoBalanceReducer";
import paymentReducer from "./paymentReducer";
import subscriptionReducer from "./subscriptionReducer";
import wheelReducer from "./wheelReducer";
import { combineReducers } from "@reduxjs/toolkit";

export interface StoreState {
  common: any;
  ranking: any;
  tournamentPrizes: any;
  userFriendList: any;
  tournaments: any;
  stats: any;
  history: any;
  transactions: any;
  userTournaments: any;
  advertisements: any;
  rewards: any;
  voucherRewards: any;
  ui: any;
  authlink: any;
  authlinkkey: any;
  statusPoint: any;
  socialAuthStatus: any;
  walletToken: any;
  partnerBalance: any;
  layout: any;
  userAuthentication: any;
  userLogin: any;
  prizeRedemption: any;
  arcadeGames: any;
  mission: any;
  achievement: any;
  sdkReducer: ISdkState;
  notification: any;
  modal: any;
  team: any;
  gameplay: any;
  maxScore: any;
  appleAuthentication: any;
  monopoly?: any;
  gamesOfTheWeek: any;
  paymentState: any;
  subscription: any;
  spinWheelInfo: any;
}

const rootReducer = combineReducers({
  common: commonReducer,
  gameplay: gameplayReducer,
  ranking: tournamentRankingReducer,
  tournamentPrizes: tournamentPrizesReducer,
  userFriendList: userFriendListReducer,
  history: historyReducer,
  tournaments: activeTournamentsReducer,
  stats: statsReducer,
  transactions: transactionsReducer,
  userTournaments: userTournamentsReducer,
  advertisements: advertisementsReducer,
  rewards: tournamentRewardsReducer,
  voucherRewards: voucherCodeRewardsReducer,
  ui: uiReducer,
  authlink: authUserLoginReducers,
  authlinkkey: authLinkKey,
  statusPoint: statusPointReducer,
  socialAuthStatus: socialAuthStatusReducer,
  walletToken: tokenReducer,
  layout: layoutReducer,
  userAuthentication: userAuthenticationReducer,
  userLogin: userLoginReducer,
  prizeRedemption: prizeRedemptionReducer,
  arcadeGames: arcadeGameReducer,
  mission: missionReducer,
  achievement: achievementReducer,
  sdkReducer: sdkReducer,
  notification: notificationReducer,
  modal: modalReducer,
  team: teamReducer,
  maxScore: scoreReducer,
  appleAuthentication: appleSigninReducer,
  monopoly: monopolyReducer,
  gamesOfTheWeek: gamesOfTheWeekReducer,
  partnerBalance: partnerInfoBalanceReducer,
  paymentState: paymentReducer,
  subscription: subscriptionReducer,
  spinWheelInfo: wheelReducer,
});

export default rootReducer;
