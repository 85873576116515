import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import cn from "classnames";
import { APP_SLUGS } from "constants/constants";
import { decodeDataFromURL, isStringEqual } from "utils";
import LoadingIndicator from "./LoadingIndicator";
import OOREDOO_LOADING from "assets/clients/Ooredoo/ooredoo_loading.gif";
import { useAppSelector } from "redux/Store";
import { isBarca, isBonoxs } from "utils/applicationSlug";
import BarcaLoadingBackground from "assets/images/Barca/Backgrounds/Loading.png";

const loaderStyle = (theme: ITheme) => css`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 200000000;
  top: 0;
  left: 0;
  transition: background 0.2s ease-in-out;
  background: ${theme.background.primary};

  &.razerLoader {
    background: #171717;
  }

  &.ooredooLoader {
    background: #8d8b8c;

    img {
      height: auto;
      width: 80px;
      border-radius: 15%;
      background: #ffffff;
      padding: 15px;
    }
  }

  &.bubbaloo {
    .loading-style {
      background: ${theme.button.primaryGradientStart};
    }
  }

  &.bonoxs {
    background-color: #000000;

    .loading-style {
      background: #ffff00;
    }
  }

  &.claro {
    .loading-style {
      background: #da291c;
    }
  }

  &.barca-games {
    background: url(${BarcaLoadingBackground});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 103vw;

    .loading-style {
      display: none;
    }
  }
`;

export default function FullScreenLoadingIndicator(style: any) {
  const { app_slug } = decodeDataFromURL();
  const { platformLoaded } = useAppSelector((state: any) => state.layout);
  const [imageSrc, _setImageSrc] = useState(false);
  const { application } = useAppSelector((reduxState: any) => reduxState.common);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      _setImageSrc(true);
    };
    img.src = OOREDOO_LOADING;
  }, []);

  if (isStringEqual(app_slug || "", APP_SLUGS.OOREDOO) && !platformLoaded && imageSrc) {
    return (
      <div css={loaderStyle} className={cn("desktop-loader ooredooLoader")} style={style}>
        <img src={OOREDOO_LOADING} alt="" />
      </div>
    );
  }

  return (
    <div
      data-testid="full-screen-loading-indicator"
      css={loaderStyle}
      className={cn(
        `desktop-loader ${application?.slug}`,
        { razerLoader: app_slug === APP_SLUGS.RAZER },
        { bonoxs: isBonoxs },
        { "barca-games": isBarca },
      )}
      style={style}
    >
      <LoadingIndicator size={6} color="#8B00F0" />
    </div>
  );
}
