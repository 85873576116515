import { css } from "@emotion/react";
import AMX_REGULAR from "assets/fonts/Claro/AMX-REGULAR.woff";
import AMX_BOLD from "assets/fonts/Claro/AMX-BOLD.woff";

const claroStyles = css`
  @font-face {
    font-family: "AMX-REGULAR";
    src: local("AMX-REGULAR"), url(${AMX_REGULAR}) format("woff");
  }

  @font-face {
    font-family: "AMX-BOLD";
    src: local("AMX-BOLD"), url(${AMX_BOLD}) format("woff");
  }

  &.claro {
    .game-page-inner {
      background-color: #ffffff;
    }

    .header-style {
      padding: 0 0.938rem !important;

      .no-back-btn {
        width: 100%;
      }
    }

    .title-md {
      color: #2d2d2d;
      font-family: "AMX-BOLD", sans-serif;
    }

    .navbar-container {
      background: transparent;
      border-bottom: 0.031rem solid #b3b3b3;

      .emptyDivCss,
      .button-group {
        display: none;
      }

      .goama-title {
        color: #2d2d2d;
        font-family: "AMX-BOLD", sans-serif;
        font-size: 1.25rem;
        font-weight: bold;
        line-height: 1.875rem;
        margin: 0 !important;
      }

      .profile-button {
        background-color: transparent;
        border: none;
        cursor: pointer;
      }
    }

    .play-button {
      background-color: #da291c !important;
    }

    .top-tournament-full-width {
      margin: 1.25rem 0 0 0.938rem !important;

      .title-md {
        margin-left: 0 !important;
      }

      .top-tournament-card {
        border-radius: 0.75rem;
        box-shadow: 0.125rem 0.125rem 0.625rem #00000029;
        height: 17.438rem;

        .card {
          border-radius: 0.53rem;
          height: 14.5rem;
        }

        .clock-participant-container {
          .clock-participant-bg-svg {
            display: none;
          }

          .clock-participant-text {
            bottom: auto;
            justify-content: space-between;
            left: auto;
            margin-top: 0.438rem;
            padding: 0 0.625rem;
            position: relative;

            p {
              color: #2d2d2d;
              font-size: 0.75rem;
              font-weight: bold;
              line-height: 1rem;
            }
          }
        }
      }
    }

    .desktop-footer {
      .footer-content {
        backdrop-filter: blur(1.25rem);
        background: #cecece 0% 0% no-repeat padding-box;
        border-radius: 0.625rem 0.625rem 0 0;
        box-shadow: inset 0 0.188rem 0.625rem #24415d0f, 0.188rem 0.188rem 0.625rem #24415d29;

        svg {
          path,
          circle {
            fill: #006e7a;
          }
        }

        .active-link {
          color: #da291c;

          path,
          circle {
            fill: #da291c;
          }
        }
      }
    }

    .progress-share-overlay {
      .nav-style {
        display: none;
      }
    }

    .half-style {
      background: #ffffff;

      .latest-score-container {
        span {
          color: #000000;
        }

        .latest-score-label {
          color: #1f1f1f;
          font-size: 1.25rem;
          font-weight: bold;
          line-height: 1.875rem;
        }
      }

      .player-rank-board {
        .tile {
          background-color: #c7251a;
          border-radius: 0.53rem;

          path {
            display: none;
          }
        }

        .first-board,
        .last-board {
          display: flex;
          flex-direction: column-reverse;

          .tilemap1,
          .tilemap3 {
            height: 90%;
          }
        }

        .label-style {
          background-color: #f65041;
        }
      }

      .second-half {
        border-top: 0.063rem solid #b3b3b3;
        padding-top: 1.25rem;
        margin: 0 1rem;

        .btn-container {
          background: #ffffff;
          border-radius: 0.625rem;
          box-shadow: 0.125rem 0.125rem 0.5rem #2f2f6026;
          display: flex;
          flex-direction: column;
          height: 4.625rem;
          width: 6rem;

          .claro-home-icon {
            path {
              fill: #006e7a;
            }
          }

          .icon-label {
            color: #404040;
            font-size: 0.688rem;
            font-weight: medium;
            line-height: 1.063rem;
          }
        }
      }
    }

    .page-title {
      .d-flex {
        justify-content: center !important;

        .btn-link {
          display: none;
        }
      }
    }

    .browse-game-item {
      border: none;

      .item-container {
        border-radius: 0.75rem;
        box-shadow: 0.188rem 0.188rem 0.625rem #00000029;

        .img-div {
          .img-style {
            align-items: flex-start;
            background-color: #ffffff !important;
            display: flex;
            padding: 0.625rem !important;
            flex-direction: column;
            width: fit-content;

            .claro-game-name {
              color: #1f1f1f;
              font-size: 0.875rem;
              font-weight: bold;
              line-height: 1.313rem;
              margin-bottom: 0.5rem;
            }

            .thumbnail {
              left: auto !important;
              margin: 0 !important;
              position: relative;
              top: auto !important;
              width: 6.188rem !important;
              height: 6.375rem;
            }
          }
        }

        .tournament-info-container {
          display: flex;
          flex-shrink: unset;
          justify-content: space-between;
          margin-top: 1.875rem;
          padding: 0;

          .tournament-info-list {
            list-style: none;
            margin: 0;
            padding: 0;

            .tournament-info-list-item {
              margin-bottom: 1rem;

              svg {
                fill: #006e7a;
              }

              .icon-label {
                color: #2d2d2d;
                font-size: 0.75rem;
                font-weight: bold;
                line-height: 1rem;
                margin-left: 0.313rem;
              }
            }
          }

          .button-container {
            display: flex;
            justify-content: center;
            margin-right: 0.625rem;

            .rank-btn {
              align-items: center;
              border: none;
              border-radius: 0.625rem;
              box-shadow: 0.125rem 0.125rem 0.5rem #2f2f6026;
              display: flex;
              flex-direction: column;
              height: 4.625rem;
              justify-content: center;
              width: 6rem;

              .icon-label {
                color: #404040;
                font-size: 0.688rem;
                font-weight: medium;
                line-height: 1.063rem;
              }
            }
          }
        }

        .play-button {
          border: none;
          border-radius: 60rem;
          color: #ffffff;
          font-size: 1rem;
          font-weight: medium !important;
          height: 2.5rem;
          line-height: 1.313rem;
          margin: 1.375rem 2.813rem;
          width: auto;
        }
      }
    }

    .recommended-games {
      padding-top: 0 !important;
    }

    .profile-title {
      color: #1f1f1f;
      font-family: "AMX-BOLD", sans-serif;
      font-size: 1.25rem;
      font-weight: bold;
      line-height: 1.875rem;
      margin-top: 1.25rem;
      text-align: center;
    }

    .user-profile-wrapper {
      .mobile {
        align-items: center;
        justify-content: center;

        .token-sty {
          display: none;
        }

        .title-md {
          color: #2d2d2d;
          font-family: Roboto;
          font-size: 0.875rem;
          font-weight: normal;
          line-height: 1rem;
        }

        .user-profile-customization {
          bottom: 0;
          cursor: pointer;
          position: absolute !important;
          right: 0;

          .support-style {
            background: transparent !important;
            border-radius: 50%;
            padding: 0 !important;
            width: 1.5rem !important;
            height: 1.5rem !important;

            div {
              padding: 0 !important;

              .edit-button {
                align-items: center;
                background: #ffffff !important;
                border-radius: 50%;
                border: none !important;
                box-shadow: 0 0.188rem 0.375rem #00000029;
                display: flex;
                height: 2.375rem !important;
                justify-content: center;
                padding: 0 !important;
                width: 2.375rem !important;

                .edit-icon {
                  fill: #404040 !important;
                  height: 1rem !important;
                  left: auto !important;
                  margin: 0 !important;
                  position: relative;
                  top: auto !important;
                  width: 1rem !important;
                }
              }
            }
          }
        }
      }
    }

    .profile-history-wrapper {
      .list-group-item {
        background: #f4f4f4;
        border-radius: 0.625rem 0.625rem 0 0;

        .header-text {
          color: #404040;
          font-family: Roboto;
          font-size: 0.75rem;
          font-weight: bold;
          line-height: 1rem;
        }
      }
    }

    .REGULAR_game_page {
      .custom-popup-width {
        background: #ffffff;
        border-radius: 0.75rem 0.75rem 0 0;
        box-shadow: 0.188rem 0.188rem 0.938rem #00000029;
      }
    }
  }
`;

export default claroStyles;
