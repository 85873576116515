import React, { useState, useEffect } from "react";
import { css, useTheme } from "@emotion/react";
import { useHistory } from "hooks/useHistory";
// import history from "HistoryWrapper";
import { useAppDispatch, useAppSelector } from "redux/Store";
import { parseDuration } from "utils";
import analytics from "utils/analytics";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { Trans } from "react-i18next";
import cn from "classnames";
// import useQueryParams from "hooks/useQueryParams";
import TournamentDetailsFooter from "components/feature-specific/leaderboard/LeaderboardPopUp/TournamentDetailsFooter";
import RankListContainer from "components/feature-specific/ranks/RankListContainer/RankListContainer";
import { fetchTournamentAdvertisements, fetchTournamentDetails } from "actions";
import { ReactComponent as Close } from "assets/images/closedown.svg";
import PrizesSlider from "../../ranks/PrizesSlider";
import { MotionButton } from "../../../common/Buttons/MotionButton";
// import tournamentsAPI from "actions/api/tournaments";
import PrizeList from "../../ranks/PrizeCalculator/PrizeList";
import DraggableModal from "components/feature-specific/Modals/DraggableModal";
import Advertisement from "../../advertisement/Advertisement";
import { useRollbar } from "@rollbar/react";
import api from "actions/api";
import { hideLeaderboardPopup } from "redux/reducers/layoutReducer";
import { useSearchParams } from "react-router-dom";

export default function LeaderboardPopupItem() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  // const searchParams = new URLSearchParams(window.location.search);
  // const queryParams = useQueryParams();
  const [playable, setPlayable] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [prizes, setPrizes] = useState<any[]>([]);
  const { allTournaments, allGames } = useAppSelector((state: any) => state.tournaments);
  const { participants } = useAppSelector((state: any) => state.userTournaments);
  const { showAds, application } = useAppSelector(state => state.common);
  const theme: ITheme = useTheme();
  const { isDesktop, tournament: tournamentId, openPrize } = useAppSelector(state => state.layout);

  const [participant, setParticipant] = useState<IParticipant | null>(null);
  const [game, setGame] = useState<IGame | null>();
  // const [participant, setParticipant] = useState(null);
  {
    /* //NOTE - THIS TOURNAMENT ID IS COMING FROM URL THAT SET THROUGH BANNER  */
  }
  const urlTournamentId = searchParams.get("tournament");

  const tournament: ITournament = allTournaments.find((t: ITournament) => t?.id === (tournamentId || urlTournamentId));

  // const participant: IParticipant = participants.find((p: IParticipant) => p.tournament === tournamentId);
  // const game: IGame = allGames.find((g: IGame) => g.slug === tournament?.game);

  useEffect(() => {
    setParticipant(participants?.find((p: IParticipant) => p.tournament === tournamentId));
    setGame(allGames.find((g: IGame) => g?.slug === tournament?.game));
    // RollbarClient?.error("Updated Tournament in Leaderboard", "TOURNAMNET", { tournament, participants, participant });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [[...allGames], participant, participants, tournament, tournament?.game, tournamentId]);

  // useEffect(() => {
  //   /* if (
  //     history.location.pathname !== "/tournament" &&
  //     history.location.pathname !== "/tournament/my-games" &&
  //     history.location.pathname !== "/tournament/missions"
  //   ) {
  //     // history.push({ search: "" });
  //     dispatch({
  //       type: ActionTypes.HIDE_LEADERBOARDPOPUP
  //     });
  //   } */
  // }, [dispatch, history, history.location.pathname, tournamentId]);

  // const queryParamTournament = queryParams.get("tournament");
  // useEffect(() => {
  //   window.onpopstate = function (e) {

  //     history.replace({ search: "" });
  //     dispatch({
  //       type: ActionTypes.HIDE_LEADERBOARDPOPUP
  //     });
  //   };
  // }, [dispatch, history]);

  useEffect(() => {
    if (openPrize === "true") {
      setClicked(true);
    } else {
      setClicked(true);
    }
  }, [openPrize]);

  useEffect(() => {
    if (tournamentId) {
      document.getElementsByTagName("html")[0].style.overflow = "hidden";
    } else {
      document.getElementsByTagName("html")[0].style.overflow = "unset";
    }
    return () => {
      document.getElementsByTagName("html")[0].style.overflow = "unset";
    };
  }, [tournamentId, history.location.pathname]);

  /**
   * logic to disable scroll in background body
   * when popup is open
   * * FOR iOS
   */
  useEffect(() => {
    if (tournamentId) {
      document.body.classList.add("body-overflow-hidden");
      document?.getElementsByClassName("custom-page")[0]?.classList?.add("custom-page-modal-open");
    } else {
      document.body.classList.remove("body-overflow-hidden");
      document.body.classList.remove("body-top-tournament");
      document?.getElementsByClassName("custom-page")[0]?.classList.remove("custom-page-modal-open");
    }
    return () => {
      document.body.classList.remove("body-overflow-hidden");
      document.body.classList.remove("body-top-tournament");
      document?.getElementsByClassName("custom-page")[0]?.classList?.remove("custom-page-modal-open");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tournamentId,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    document?.getElementsByClassName("custom-page")[0],
    history.location.pathname,
  ]);

  const rollbar = useRollbar();

  useEffect(() => {
    if (tournamentId) {
      dispatch(fetchTournamentDetails(tournamentId));
      if (showAds) {
        dispatch(fetchTournamentAdvertisements(tournamentId));
      }
    }
  }, [dispatch, tournamentId, showAds, rollbar]);

  useEffect(() => {
    let timer: any;
    if (tournament) {
      // set analytics
      analytics.setTournamentData(tournament);
      const lastEntryTime = new Date(tournament.end_time.getTime() - parseDuration(tournament.entry_close_mins));
      // Set Playable on Component mount
      setPlayable(
        !!participant ||
          (lastEntryTime > new Date() &&
            (tournament.max_participant > 0 ? tournament.participant_count < tournament.max_participant : true)),
      );

      // Disable playNow on Tournament End
      if (!participant) {
        timer = setInterval(() => {
          if (lastEntryTime < new Date()) setPlayable(false);
        }, 1000);
      } else {
        timer = setInterval(() => {
          if (tournament.end_time < new Date()) setPlayable(false);
        }, 1000);
      }
    }
    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line
  }, [tournament, participant, tournamentId]);

  const onTapToSeeBtnClick = () => {
    setClicked(!clicked);
  };

  useEffect(() => {
    if (tournament) {
      api
        .get(`tournaments/${tournament?.id}/prizes/${tournament?.participant_count}`)
        .then(response => setPrizes(response.data))
        .catch(() => setPrizes([]));
    }
    /* return () => {
      setClicked(false);
    }; */
  }, [tournament]);

  const hideTapToSeeBtn = tournament?.prize_calculation === "manual" && prizes?.length === 0;

  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (tournamentId === 0) {
      setOpenModal(false);
    } else {
      setOpenModal(true);
    }
  }, [tournamentId]);

  const onDismiss = (): void => {
    setOpenModal(false);
    history.push({ search: "" });
    setClicked(false);
    dispatch(hideLeaderboardPopup());
    // urlObject.push({ search: "" });
  };

  return (
    <div>
      <DraggableModal openModal={openModal} onDismiss={onDismiss}>
        <div
          css={isDesktop ? deskCss : cardCSS}
          // css={cn(deskCss, {
          //   cardCSS: isDesktop,
          // })}
          className={
            isDesktop
              ? `leaderboard-popup-item desktop ${application?.slug}`
              : `leaderboard-popup-item leaderboard-width ${application?.slug}`
          }
        >
          <div className="modal_header text-center">
            <div className="img-div-style col-12 text-center">
              <img src={game?.cover_image} alt="coverimage" className="img-style " />
            </div>

            {!isDesktop && (
              <span
                className={cn("gamename-style text-center title-md", {
                  "pt-5 mb-5": !isDesktop,
                  "mb-2": isDesktop,
                  // "pt-5 mt-3": isDesktop,
                })}
              >
                {tournament?.list_title || game?.name}
              </span>
            )}

            {!isDesktop && <div className="drag-handle" />}

            <div className="col-12 top-sticky-ranking-page">
              <Advertisement name="Top Sticky Ranking Page" />
            </div>
          </div>

          <div className="leaderboard-wrapper">
            <div className="close_wrapper">
              <Close
                fill={theme.text.secondary}
                className="close"
                onClick={() => {
                  setOpenModal(false);
                  setClicked(false);
                  dispatch(hideLeaderboardPopup());
                }}
              />
            </div>
            <div className="leaderboard-container mt-0">
              <div className="modal_body px-3">
                {isDesktop && (
                  <span
                    className={cn("gamename-style text-center title-md", {
                      "pt-5 mb-5": !isDesktop,
                      "mb-2": isDesktop,
                      // "pt-5 mt-3": isDesktop,
                    })}
                  >
                    {tournament?.list_title || game?.name}
                  </span>
                )}
                <div>
                  {!!game && (
                    <PrizesSlider
                      currentTournament={tournament}
                      game={game}
                      onTournamentChange={(tour: ITournament) => {
                        if (tour) {
                          history.push({ search: `tournament=${tour?.id}` });
                          setClicked(false);
                        }
                      }}
                    />
                  )}
                </div>
                {!hideTapToSeeBtn && (
                  <div className="btn-tap-to-see">
                    <MotionButton
                      title={
                        <div className="d-flex flex-row align-items-center justify-content-center title-xs">
                          <span className="me-1">
                            <Trans i18nKey="tap-to-see-priz">Tap to See Prizes</Trans>
                          </span>
                          {(!clicked && <FaChevronUp />) || <FaChevronDown />}
                        </div>
                      }
                      onClick={() => {
                        // setCountClicked(countClicked + 1);
                        onTapToSeeBtnClick();
                      }}
                      disabled={false}
                    />
                  </div>
                )}

                {clicked && tournament && (
                  <PrizeList
                    tournament={tournament}
                    minRange={0}
                    maxRange={tournament?.max_participant}
                    defaultValue={tournament?.participant_count}
                    color={false}
                  />
                )}

                <div>
                  <RankListContainer tournament={tournament} participant={participant as any} />
                </div>

                {game && (
                  <TournamentDetailsFooter
                    tournament={tournament}
                    participant={participant as any}
                    game={game}
                    playable={playable}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </DraggableModal>
    </div>
  );
}

const cardCSS = (theme: ITheme) => css`
  padding-top: 50px;
  border-top-left-radius: 27px !important;
  border-top-right-radius: 27px !important;
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 1021px) {
    height: 100%;
    top: 0;
  }

  .top-sticky-ranking-page {
    z-index: 999;
    position: fixed;
    left: 0;
    top: 60px;
    width: 100vw;
    margin-bottom: 12px;
  }

  .modal_body {
    overflow-y: scroll;
    @media (max-width: 1020px) {
      padding-top: 50px !important;
      overflow: scroll;
      -webkit-overflow-scrolling: auto;
    }
  }
  .leaderboard-wrapper {
    overflow-y: scroll;
    -webkit-overflow-scrolling: auto;
    @media (max-width: 1021px) {
      margin-top: 10px;
    }
    .close_wrapper {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 999999 !important;
      cursor: pointer;
    }
  }
  .leaderboard-container {
    flex-grow: 1;
    overflow-y: scroll;
    -webkit-overflow-scrolling: auto;

    @media (max-width: 1021px) {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      overflow: scroll;
      -webkit-overflow-scrolling: auto;
    }
    /* Hide scrollbar */
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    .btn-tap-to-see {
      width: 80%;
      margin-top: -15px;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      z-index: 2;
    }
  }

  .drag-handle {
    padding: 0.5rem 0 0.7rem;
    width: 100%;
    position: absolute;
    top: 70px;
    z-index: 1;

    &:after {
      content: "";
      display: block;
      width: 50px;
      height: 4px;
      margin: auto;
      border-radius: 5px;
      background-color: ${theme.text.secondary};
    }
  }

  .img-div-style {
    position: absolute;
    top: -37px;
    color: white;
    @media (max-width: 1021px) {
      top: 0;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }
  }

  .img-style {
    border-radius: 7px;
    border: 2px solid white;
    height: 70px;
    box-shadow: -1px 12px 8px 0 rgb(79 81 87 / 30%);
    @media (max-width: 1021px) {
      transform: translateY(-50%);
    }
  }

  .gamename-style {
    display: inline-block;
    font-weight: 600;
  }

  .close {
    width: 15px;
    height: 22px;
    position: absolute;
    opacity: 1.5 !important;
    top: 15px;
    right: 15px;
  }

  &.globe {
    .modal_body {
      background-color: #f3f6ff;
    }
    .modal_header {
      background-color: #f3f6ff;
    }
  }
  @media (max-width: 1021px) {
    .modal_header {
      position: fixed;
      width: 100%;
      top: -15px;
      background-color: #fff;
      max-height: 105px;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      z-index: 999;
    }
  }
`;

const deskCss = (theme: ITheme) => css`
  /* padding-top: 24px; */
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  bottom: 0;
  left: 52px;
  right: 0;
  .img-div-style {
    position: absolute;
    top: -50px;
    color: white;
    img {
      position: relative;
      width: 100px;
      height: 100px;
      z-index: 10005;
    }
  }

  .img-style {
    border-radius: 7px;
    border: 2px solid white;
    box-shadow: -1px 12px 8px 0 rgb(79 81 87 / 30%);
  }

  .prize-list-container {
    margin-top: -1rem !important;
  }

  .gamename-style {
    display: inline-block;
    font-weight: 600;
  }

  .close {
    width: 15px;
    height: 22px;
    position: absolute;
    opacity: 1.5 !important;
    top: 15px;
    right: 15px;
  }

  .leaderboard-width {
    background: white;
  }

  .leaderboard-wrapper {
    overflow-y: scroll;
    -webkit-overflow-scrolling: auto;
    .close_wrapper {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 999999 !important;
      cursor: pointer;
    }
  }
  .leaderboard-container {
    flex-grow: 1;
    overflow-y: scroll;
    -webkit-overflow-scrolling: auto;

    @media (max-width: 1021px) {
      padding-top: 80px;
    }
    /* Hide scrollbar */
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    .btn-tap-to-see {
      position: relative;
      width: 70%;
      margin-top: -15px;
      margin-left: auto;
      margin-right: auto;
      z-index: 2;
    }
  }
  .drag-handle {
    padding: 0.5rem 0 0.7rem;
    width: 100%;
    position: absolute;
    top: 100px;
    z-index: 1;

    &:after {
      content: "";
      display: block;
      width: 50px;
      height: 4px;
      margin: auto;
      border-radius: 5px;
      background-color: ${theme.text.secondary};
    }
  }
  .modal_body {
    padding-top: 68px !important;
    overflow-y: scroll;
    .gamename-style {
      color: ${theme.text.primary};
      width: 100%;
      padding-bottom: 10px !important;
    }
  }
  .modal_header {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    z-index: 999;
  }
  &.globe {
    .modal_body {
      background-color: #f3f6ff;
    }
  }
`;
