import { css } from "@emotion/react";

const danaStyles = (theme: ITheme) => css`
  &.dana {
    .cancel-btn {
      background: #ffffff !important;
      border-color: #000000 !important;
      border: 1px solid ${theme.button.primaryGradientStart} !important;
      color: #000000 !important;
    }
    .show-all-text {
      color: black !important;
    }

    .title-text {
      color: black !important;
    }

    .coming-soon-text {
      color: black !important;
    }

    .competitor-name {
      color: black !important;
    }

    .own-score {
      color: black !important;
    }

    .competitor-score {
      color: black !important;
    }
    .tournament-subtitle {
      font-size: 12.45px;
      letter-spacing: 0.5px;
      font-weight: 600;
    }
    .top-bar-container {
      .marquee {
        font-size: 15.3px !important;
      }
    }
    .campaign-message-modal {
      .close-button {
        top: 20px;
        right: 20px;
        background-color: #ffffff;
        border-radius: 5px;
      }
    }
    .card-win-prizes-text {
      color: #ffffff;
      padding-left: 35px !important;
    }

    .list-heading {
      background-color: #0d568b !important;
    }

    .dana-rank-list {
      margin-top: 10px !important;
    }

    .no-info {
      color: #000000 !important;
    }

    .btn-tap-to-see {
      .btn-style {
        background: #ffffff !important;
        border: 1px solid #328ee9 !important;
        color: #328ee9 !important;
      }
    }
  }
`;

export default danaStyles;
