import React from "react";
import { css } from "@emotion/react";
import modalLogo from "assets/images/subscription/subscription_logo.png";
import { ReactComponent as CrossIcon } from "assets/images/subscription/close_icon.svg";
import CommonSubscriptionModal from "../CommonSubscriptionModal";
import { useAppSelector } from "redux/Store";
import useModalHandler from "hooks/useModalHandler";
import { Trans } from "react-i18next";

const PaymentFailedModalStyle = () => css`
  .modal-body-icon {
    height: 95px;
    width: 95px;
    border-radius: 50%;
    padding: 20px;
    background-color: #ed1c24;
    fill: white !important;
    box-shadow: 1px 3px 29px 0px rgba(150, 150, 150, 0.21);
    color: white;
    path {
      fill: white !important;
    }
  }
`;

export default function PaymentFailedModal() {
  const { showPaymentFailedModal } = useAppSelector((state: any) => state.modal);
  const { closePaymentFailedModal } = useModalHandler();

  return (
    <CommonSubscriptionModal
      show={showPaymentFailedModal}
      onHide={closePaymentFailedModal}
      title={<Trans i18nKey="payment-failed">Payment Failed</Trans>}
      logo={modalLogo}
      footer={
        <button onClick={closePaymentFailedModal}>
          <Trans i18nKey="ok">Ok</Trans>
        </button>
      }
      style={PaymentFailedModalStyle}
    >
      <div className="modal-body-container">
        <CrossIcon className="modal-body-icon" />
      </div>
    </CommonSubscriptionModal>
  );
}
