import { lazy } from "react";
import MyGamesPage from "routes/my-games/mobile/MyGamesPage";

export const commonRoutes = [
  {
    path: "/login",
    exact: true,
    component: lazy(() => import("routes/auth/login")),
  },
  {
    path: "/signup",
    exact: true,
    component: lazy(() => import("routes/auth/signup")),
  },
  {
    path: "/forgetpassword",
    exact: true,
    component: lazy(() => import("routes/auth/forget-password")),
  },
  {
    path: "/verifyotp",
    exact: true,
    component: lazy(() => import("routes/auth/verify-otp")),
  },
  {
    path: "/resetpassword",
    exact: true,
    component: lazy(() => import("routes/auth/reset-password")),
  },
  {
    path: "/tournaments/faq",
    exact: true,
    component: lazy(() => import("routes/information/faq")),
  },
  {
    path: "/tournaments/privacypolicy",
    exact: true,
    component: lazy(() => import("routes/information/privacy-policy")),
  },

  {
    path: "/tournaments/contact-us",
    exact: true,
    component: lazy(() => import("routes/information/contact-us")),
  },
  {
    path: "/tournaments/termsandconditions",
    exact: true,
    component: lazy(() => import("routes/information/terms-and-conditions")),
  },
  {
    path: "/profile",
    exact: true,
    component: lazy(() => import("routes/user/profile/index")),
  },
  {
    path: "/arcade",
    exact: true,
    component: lazy(() => import("routes/arcade")),
  },
  {
    path: "/tournament/heroes-section",
    exact: true,
    component: lazy(() => import("routes/heroes-section")),
  },

  {
    path: "/tournament-history",
    exact: true,
    component: lazy(() => import("routes/tournaments/tournament-history")),
  },
  {
    path: "/tickets",
    exact: true,
    component: lazy(() => import("routes/tickets")),
  },
  {
    path: "/ticket-rewards",
    exact: true,
    component: lazy(() => import("routes/tickets/rewards")),
  },
  {
    path: "/token-profile",
    exact: true,
    component: lazy(() => import("routes/tokens")),
  },
  {
    path: "/redeem",
    exact: true,
    component: lazy(() => import("routes/tokens/redeem")),
  },
  {
    path: "/ticket-redeem",
    exact: true,
    component: lazy(() => import("routes/tickets/redeem")),
  },
  {
    path: "/achievement",
    exact: true,
    component: lazy(() => import("routes/achievements")),
  },
  {
    path: "/reward",
    exact: true,
    component: lazy(() => import("routes/rewards")),
  },
  {
    path: "/notifications",
    exact: true,
    component: lazy(() => import("routes/notifications")),
  },
  {
    path: "/tournament/missions",
    exact: true,
    component: lazy(() => import("routes/missions")),
  },
  {
    path: "/cta-page",
    exact: true,
    component: lazy(() => import("routes/cta")),
  },
  {
    path: "/partner-balance",
    exact: true,
    component: lazy(() => import("routes/partner/balance")),
  },
  {
    path: "/nurture",
    exact: true,
    component: lazy(() => import("routes/nurture")),
  },
  {
    path: "/monopoly-landing",
    exact: true,
    component: lazy(() => import("routes/monopoly")),
  },
  {
    path: "/monopoly",
    exact: false,
    component: lazy(() => import("routes/monopoly/MonopolyGamePage")),
  },
  {
    path: "/nftazos",
    exact: true,
    component: lazy(() => import("routes/nftazos/pages/Battles")),
  },
  {
    path: "/nftazos/pvp",
    exact: true,
    component: lazy(() => import("routes/nftazos/pages/PVP")),
  },
];

export const desktopRoutes = [
  {
    path: "/",
    component: lazy(() => import("routes/home/desktop")),
  },
  {
    path: "/tournament",
    exact: true,
    component: lazy(() => import("routes/home/desktop")),
  },
  ...commonRoutes,
  {
    path: "/tournament/play-featured-game",
    exact: true,
    component: lazy(() => import("routes/tournaments/desktop/FeaturedGameDirectPlay")),
  },
  {
    path: "/tournament/play-featured-game/:serial",
    exact: true,
    component: lazy(() => import("routes/tournaments/desktop/FeaturedGameDirectPlay")),
  },
  {
    path: "/tournaments/:tournamentId/play",
    exact: true,
    component: lazy(() => import("routes/play-game/tournament/desktop/play")),
  },
  {
    path: "/pvp-page/:tournamentId",
    exact: true,
    component: lazy(() => import("routes/play-game/pvp/mobile/desktop/PlayerVsPlayer")),
  },
  {
    path: "/arcade/categories/:categoryId",
    exact: true,
    component: lazy(() => import("routes/arcade/desktop/CategoryArcadePage")),
  },
  {
    path: "/C2PplayGamePage",
    exact: true,
    component: lazy(() => import("routes/play-game/arcade/desktop/ArcadePlayGamePage")),
  },
  {
    path: "/pvp/tournaments/:gameSlug",
    exact: true,
    component: lazy(() => import("routes/pvp/desktop/PVPTournamentList")),
  },
];

export const mobileRoutes = [
  {
    path: "/",
    exact: true,
    component: lazy(() => import("routes/home/mobile")),
    componentTwo: lazy(() => import("routes/home/mobile/single-featured-game")),
  },
  {
    path: "/tournament",
    exact: true,
    component: lazy(() => import("routes/home/mobile")),
    componentTwo: lazy(() => import("routes/home/mobile/single-featured-game")),
  },
  ...commonRoutes,
  {
    path: "/tournaments/:tournamentId",
    exact: true,
    component: lazy(() => import("routes/tournaments/mobile/TournamentDetails")),
  },
  {
    path: "/tournaments/:tournamentId/play/:init?",
    exact: true,
    component: lazy(() => import("routes/play-game/tournament/mobile/play")),
  },
  {
    path: "/C2PplayGamePage",
    exact: true,
    component: lazy(() => import("routes/play-game/arcade/mobile/ArcadePlayGamePage")),
  },
  {
    path: "/arcade/categories/:categoryId",
    exact: true,
    component: lazy(() => import("routes/arcade/mobile/CategoryArcadePage")),
  },
  {
    path: "/pvp-page/:tournamentId",
    exact: true,
    component: lazy(() => import("routes/play-game/pvp/mobile/PlayerVsPlayer")),
  },
  {
    path: "/pvp/tournaments/:gameSlug",
    exact: true,
    component: lazy(() => import("routes/pvp/mobile/PvpTournamentList")),
  },
  {
    path: "/pvp/games/:gameSlug",
    exact: true,
    component: lazy(() => import("routes/tournaments/mobile/GameWiseTournamentList")),
  },
  {
    path: "/games/:gameSlug",
    exact: true,
    component: lazy(() => import("routes/tournaments/mobile/GameWiseTournamentList")),
  },
  {
    path: "/tournament/play-featured-game",
    exact: true,
    component: lazy(() => import("routes/tournaments/mobile/FeaturedGameDirectPlayPage")),
  },
  {
    path: "/tournament/play-featured-game/:serial",
    exact: true,
    component: lazy(() => import("routes/tournaments/mobile/FeaturedGameDirectPlayPage")),
  },
  {
    path: "/tournament/my-games",
    exact: true,
    component: MyGamesPage,
  },
];

// mobile pages
/* const allRoutes: any = [
  {
    routeType: "common",
    routes: [],
  },
  {
    routeType: "desktop",
    routes: [
      {
        path: "/",
        component: DESKTOP_HomePage,
        desktop: true,
        key: "ROUTE_ROOT_PAGE",
      },
      {
        path: "/tournament",
        exact: true,
        component: DESKTOP_HomePage,
        desktop: true,
        key: "ROUTE_HOMEPAGE",
      },
      {
        path: "/tournaments/privacypolicy",
        exact: true,
        component: PrivacyPolicyPage,
        desktop: true,
        key: "ROUTE_PRIVACY_POLICY_PAGE",
      },
      {
        path: "/tournaments/termsandconditions",
        exact: true,
        component: TermsAndConditionsPage,
        desktop: true,
        key: "ROUTE_TERMS_AND_CONDITIONS_PAGE",
      },
      {
        path: "/tournaments/faq",
        exact: true,
        component: FAQPage,
        desktop: true,
        key: "ROUTE_FAQPAGE",
      },
      {
        path: "/tournaments/contact-us",
        exact: true,
        component: ContactUsPage,
        desktop: true,
        key: "ROUTE_CONTACT_US_PAGE",
      },
      {
        path: "/tournament/play-featured-game",
        exact: true,
        component: FeaturedGameDirectPlayPage,
        desktop: true,
        key: "ROUTE_DIRECT_PLAY_PAGE",
      },
      {
        path: "/tournament/play-featured-game/:serial",
        exact: true,
        component: FeaturedGameDirectPlayPage,
        desktop: true,
        key: "ROUTE_DIRECT_PLAY_PAGE_WITH_PARAM",
      },
      {
        path: "/tournaments/:tournamentId/play",
        exact: true,
        component: DESKTOP_PlayGamePage,
        desktop: true,
        key: "ROUTE_PLAYGAMEPAGE",
      },
      {
        path: "/pvp-page/:tournamentId",
        exact: true,
        component: DESKTOP_PlayerVsPlayer,
        desktop: true,
        key: "ROUTE_PlayerVsPlayer",
      },
      {
        path: "/login",
        exact: true,
        component: DESKTOP_LoginPage,
        desktop: true,
        key: "ROUTE_LOGINPAGE",
      },
      {
        path: "/signup",
        exact: true,
        component: DESKTOP_SignupPage,
        desktop: true,
        key: "ROUTE_SIGNUPPAGE",
      },
      {
        path: "/arcade",
        exact: true,
        component: DESKTOP_ArcadeGamesPage,
        desktop: true,
        key: "ROUTE_ARCADEGAMESPAGE",
      },
      {
        path: "/arcade/categories/:categoryId",
        exact: true,
        component: DESKTOP_CategoryArcadePage,
        desktop: true,
        key: "ROUTE_CategoryArcadePage",
      },
      {
        path: "/C2PplayGamePage",
        exact: true,
        component: DESKTOP_ArcadePlayGamePage,
        desktop: true,
        key: "ROUTE_C2PPlayGamePage",
      },
      {
        path: "/forgetpassword",
        exact: true,
        component: DESKTOP_ForgetPasswordPage,
        desktop: true,
        key: "ROUTE_ForgetPasswordPage",
      },
      {
        path: "/resetpassword",
        exact: true,
        component: DESKTOP_ResetPasswordPage,
        desktop: true,
        key: "ROUTE_ResetPasswordPage",
      },
      {
        path: "/verifyotp",
        exact: true,
        component: DESKTOP_VerifyOTPPage,
        desktop: true,
        key: "ROUTE_VerifyOTPPage",
      },
      {
        path: "/tournament/heroes-section",
        exact: true,
        component: HeroesSectionPage,
        desktop: true,
        key: "ROUTE_HeroesSectionPage",
      },
      {
        path: "/pvp/tournaments/:gameSlug",
        exact: true,
        component: DESKTOP_PVPTournamentList,
        desktop: true,
        key: "ROUTE_PVPGameWiseDesktopTournamentList",
      },
      {
        path: "/profile",
        exact: true,
        component: MobileUserProfilePage,
        desktop: true,
        key: "ROUTE_USERPROFILE",
      },
      {
        path: "/tournament-history",
        exact: true,
        component: MobileTournamentHistoryPage,
        desktop: true,
        key: "MOBILE_ROUTE_TOURNAMENT_HISTORY_PAGE",
      },
      {
        path: "/redeem",
        exact: true,
        component: DESKTOP_TokenRedeemPage,
        desktop: true,
        key: "ROUTE_TokenRedeemPage",
      },
      {
        path: "/token-profile",
        exact: true,
        component: DESKTOP_TokenSectionPage,
        desktop: true,
        key: "ROUTE_TokenSectionPage",
      },
      {
        path: "/reward",
        exact: true,
        component: DESKTOP_MyRewardPage,
        desktop: true,
        key: "ROUTE_MyRewardPage",
      },
      {
        path: "/tickets",
        exact: true,
        component: TicketsPage,
        desktop: true,
        key: "ROUTE_TICKETS_PAGE",
      },
      {
        path: "/ticket-redeem",
        exact: true,
        component: DESKTOP_TicketDesktopRedeemPage,
        desktop: true,
        key: "ROUTE_TICKETS_DESKTOP_REDEEM_PAGE",
      },
      {
        path: "/achievement",
        exact: true,
        component: AchievementPage,
        desktop: true,
        key: "ROUTE_ACHIEVEMENT_DESKTOP_PAGE",
      },
      {
        path: "/ticket-rewards",
        exact: true,
        component: DESKTOP_TicketDesktopRewardPage,
        desktop: true,
        key: "ROUTE_TICKETS_DESKTOP_REWARD_PAGE",
      },
      {
        path: "/tournament/missions",
        exact: true,
        component: MissionsPage,
        key: "ROUTE_MISSIONS_PAGE",
      },
      {
        path: "/partner-balance",
        exact: true,
        component: MobilePartnerBalanceInfoPage,
        key: "DESKTOP_PARTNER_BALANCE_INFO",
      },
      {
        path: "/cta-page",
        exact: true,
        component: MobileCtaLinkIframePage,
        key: "DESKTOP_ROUTE_CtaLinkPage",
      },
    ],
  },
  {
    routeType: "mobile",
    routes: [
      {
        path: "/",
        exact: true,
        component: MobileHomePage,
        componentTwo: MobileSingleFeatureGame,
        key: "MOBILE_ROUTE_ROOTPAGE",
      },
      {
        path: "/tournament",
        exact: true,
        component: MobileHomePage,
        componentTwo: MobileSingleFeatureGame,
        key: "MOBILE_ROUTE_HOMEPAGE_2",
      },
      {
        path: "/tournaments/faq",
        exact: true,
        component: MobileFAQPage,
        key: "MOBILE_ROUTE_FAQPAGE",
      },
      {
        path: "/tournaments/privacypolicy",
        exact: true,
        component: MobilePrivacyPolicyPage,
        key: "MOBILE_ROUTE_PRIVACY_POLICY_PAGE",
      },
      {
        path: "/tournaments/contact-us",
        exact: true,
        component: MobileContactUsPage,
        key: "ROUTE_CONTACT_US_PAGE",
      },
      {
        path: "/tournaments/termsandconditions",
        exact: true,
        component: MobileTermsAndConditionsPage,
        key: "MOBILE_ROUTE_TERMS_AND_CONDITIONS_PAGE",
      },
      {
        path: "/profile",
        exact: true,
        component: MobileUserProfilePage,
        key: "MOBILE_ROUTE_USERPROFILE",
      },
      {
        path: "/monopoly-landing",
        exact: true,
        component: MobileMonopolyLanding,
        key: "MOBILE_ROUTE_MONOPOLY_LANDING",
      },
      {
        path: "/monopoly",
        exact: false,
        component: MobileMonopolyGamePage,
        key: "MOBILE_ROUTE_MONOPOLY_HOME",
      },
      {
        path: "/tournaments/:tournamentId",
        exact: true,
        component: MobileTournamentDetails,
        key: "MOBILE_ROUTE_TOURNAMENTDETAILS",
      },
      {
        path: "/tournaments/:tournamentId/play/:init?",
        exact: true,
        component: MobilePlayGamePage,
        key: "MOBILE_ROUTE_PLAYGAMEPAGE",
      },
      {
        path: "/C2PplayGamePage",
        exact: true,
        component: MobileArcadePlayGamePage,
        key: "ROUTE_C2PPlayGamePage",
      },

      {
        path: "/arcade",
        exact: true,
        component: MobileArcadeGamesPage,
        key: "MOBILE_ROUTE_ARCADEGAMESPAGE",
      },
      {
        path: "/arcade/categories/:categoryId",
        exact: true,
        component: MobileCategoryArcadePage,
        key: "MOBILE_ROUTE_CategoryArcadePage",
      },
      {
        path: "/cta-page",
        exact: true,
        component: MobileCtaLinkIframePage,
        key: "MOBILE_ROUTE_CtaLinkPage",
      },
      {
        path: "/token-profile",
        exact: true,
        component: MobileTokenPage,
        key: "MOBILE_ROUTE_TOKENPAGE",
      },
      {
        path: "/pvp-page/:tournamentId",
        exact: true,
        component: MobilePlayerVsPlayer,
        key: "MOBILE_ROUTE_PlayerVsPlayer",
      },
      {
        path: "/pvp/tournaments/:gameSlug",
        exact: true,
        component: MobilePvpTournamentList,
        key: "MOBILE_ROUTE_PvpTournamentList",
      },
      {
        path: "/pvp/games/:gameSlug",
        exact: true,
        component: MobileGameWiseTournamentList,
        key: "MOBILE_ROUTE_PVPGameWiseTournamentList",
      },
      {
        path: "/redeem",
        exact: true,
        component: MobileWithdrawToken,
        key: "MOBILE_ROUTE_WITHDRAWTOKEN",
      },
      {
        path: "/login",
        exact: true,
        component: MobileAuthLoginPage,
        key: "MOBILE_ROUTE_LOGINPAGE",
      },
      {
        path: "/signup",
        exact: true,
        component: MobileAuthSignupPage,
        key: "MOBILE_ROUTE_SIGNINPAGE",
      },
      {
        path: "/forgetpassword",
        exact: true,
        component: MobileAuthForgetPasswordPage,
        key: "MOBILE_ROUTE_FORGETPASSWORDPAGE",
      },
      {
        path: "/verifyotp",
        exact: true,
        component: MobileAuthVerifyOTPPage,
        key: "MOBILE_ROUTE_VERIFYOTPPAGE",
      },
      {
        path: "/resetpassword",
        exact: true,
        component: MobileAuthResetPasswordPage,
        key: "MOBILE_ROUTE_RESETPASSWORDPAGE",
      },
      {
        path: "/notifications",
        exact: true,
        component: NotificationsPage,
        key: "MOBILE_ROUTE_NOTIFICATIONSPAGE",
      },
      {
        path: "/reward",
        exact: true,
        component: MobileRewardPage,
        key: "MOBILE_ROUTE_REWARDPAGE",
      },
      {
        path: "/ticket-rewards",
        exact: true,
        component: MobileTicketRewardPage,
        key: "MOBILE_ROUTE_TICKETREWARDPAGE",
      },
      {
        path: "/tickets",
        exact: true,
        component: MobileTicketsPage,
        key: "MOBILE_ROUTE_TICKETS_PAGE",
      },
      {
        path: "/ticket-redeem",
        exact: true,
        component: MobileTicketRedeemPage,
        key: "MOBILE_ROUTE_TICKET_REDEEM_PAGE",
      },
      {
        path: "/achievement",
        exact: true,
        component: MobileAchievementPage,
        key: "MOBILE_ROUTE_ACHIEVEMENT_PAGE",
      },
      {
        path: "/tournament-history",
        exact: true,
        component: MobileTournamentHistoryPage,
        key: "MOBILE_ROUTE_TOURNAMENT_HISTORY_PAGE",
      },
      {
        path: "/tournament/missions",
        exact: true,
        component: MobileMissionsPage,
        key: "MOBILE_ROUTE_MISSIONS_PAGE",
      },
      {
        path: "/games/:gameSlug",
        exact: true,
        component: MobileGameWiseTournamentList,
        key: "MOBILE_ROUTE_GameWiseTournamentList",
      },
      {
        path: "/tournament/heroes-section",
        exact: true,
        component: MobileHeroesSectionPage,
        key: "MOBILE_ROUTE_HEROES_PAGE",
      },
      {
        path: "/tournament/play-featured-game",
        exact: true,
        component: MobileFeaturedGameDirectPlayPage,
        key: "MOBILE_ROUTE_PLAY_FEATURED_GAME_PAGE",
      },
      {
        path: "/tournament/play-featured-game/:serial",
        exact: true,
        component: MobileFeaturedGameDirectPlayPage,
        key: "MOBILE_ROUTE_PLAY_FEATURED_GAME_SERIAL_PAGE",
      },
      {
        path: "/tournament/my-games",
        exact: true,
        component: MobileMyGamesPage,
        key: "MOBILE_ROUTE_MY_GAMES_PAGE",
      },
      {
        path: "/partner-balance",
        exact: true,
        component: MobilePartnerBalanceInfoPage,
        key: "MOBILE_PARTNER_BALANCE_INFO",
      },
      {
        path: "/nurture",
        exact: true,
        component: MobileNurture,
        key: "MOBILE_ROUTE_Nurture_PAGE",
      },
      {
        path: "/nftazos",
        exact: true,
        component: NFTazosBattlePage,
        key: "ROUTE_NFTAZOS_BATTLES",
      },
    ],
  },
]; */

// export default allRoutes;

export const routes = {
  commonRoutes,
  mobileRoutes,
  desktopRoutes,
  allRoutes: [...commonRoutes, ...mobileRoutes, ...desktopRoutes],
};

export default routes;
