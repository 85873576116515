import React from "react";
import { css } from "@emotion/react";
import modalLogo from "assets/images/subscription/subscription_logo.png";
import { ReactComponent as SadIcon } from "assets/images/subscription/sad_icon.svg";
import CommonSubscriptionModal from "../CommonSubscriptionModal";
import { useAppSelector } from "redux/Store";
import useModalHandler from "hooks/useModalHandler";
import { Trans } from "react-i18next";
import GoamaSubscriptionPaymentService from "services/payment/goama/GoamaSubscriptionPaymentService";
const UnsubscribeModalStyle = () => css`
  .modal-body-icon {
    height: 95px;
    width: 95px;
    padding: 10px;
    border-radius: 50%;
  }
`;
export default function UnsubscribeModal() {
  const { showUnsubscribeModal } = useAppSelector((state: any) => state.modal);
  const { closeUnsubscribeModal, openUnsubscribeSuccessModal } = useModalHandler();
  function handleUnsubscribeFunction() {
    const unsubscribe: any = new GoamaSubscriptionPaymentService();
    unsubscribe
      .UnsubscribeService()
      .then((res: any) => {
        if (res && res?.status) {
          if (res?.status === "success") {
            openUnsubscribeSuccessModal();
            closeUnsubscribeModal();
          }
        }
      })
      .catch((e: any) => {
        console.log({ e });
      });
  }
  return (
    <CommonSubscriptionModal
      show={showUnsubscribeModal}
      onHide={closeUnsubscribeModal}
      title={<Trans i18nKey="unsubscribe">Unsubscribe</Trans>}
      logo={modalLogo}
      backBtn={true}
      footer={
        <button onClick={handleUnsubscribeFunction}>
          <Trans i18nKey="confirm">Confirm</Trans>
        </button>
      }
      style={UnsubscribeModalStyle}
    >
      <p>
        <Trans i18nKey="are-you-sure">Are you sure you want to unsubscribe?</Trans>
      </p>
      <SadIcon className="modal-body-icon" />
    </CommonSubscriptionModal>
  );
}
