import React, { ReactNode } from "react";
import MS from "my-sabay-js-sdk";
// import { Theme } from "@emotion/react";

// import statusAPI from "actions/api/status";
import store from "redux/Store";
// import paymentsApi from "actions/api/payments";
import { changeMiniProgramPaymentVerifyModalStatus } from "redux/reducers/uiReducer";

import { ReactComponent as BrowseIcon } from "assets/images/Browse.svg";
import { ReactComponent as MyGameIcon } from "assets/images/My games.svg";
import { ReactComponent as ArcadeIcon } from "assets/images/arcade.svg";
import { ReactComponent as CrownIcon } from "assets/images/crown.svg";
import {
  getCortexAccountInfo,
  getCortexUserAuth,
  handleCortexAnalyticsEvent,
  handleCortexSocialShare,
  handleRazerPayment,
  hideCortexAd,
  isRazerGuestUser,
  showCortexAd,
} from "./razer";
import RazerSilverStatus from "components/clients/Razer/RazerSilverStatus";
import RazerGoldStatus from "components/clients/Razer/RazerGoldStatus";
import jazzcashSdk from "./jazzcash";
import bKashSdk from "./bkash";
// import { fetchUserRanking } from "Reducers/tournamentRankingReducer";
import { toast } from "react-toastify";
import { isInDevelopmentMode } from "actions/api/utils";
import { APP_SLUGS } from "constants/constants";
import klookSdk from "./klookSdk";
import RollbarClient from "utils/rollbarClient";
import ridmikSdk from "./ridmik";
import api from "actions/api";

declare global {
  interface ISdkResource {
    type: "built-in" | "external-script" | "package";
    goBackHome: Function | null;
    keepAlive?: Function;
    keepAliveInterval?: number;
    resourceLink?: string;
    // customStyles?: InterpolationWithTheme<Theme>; ! emotion/react dont have this
    customStyles?: any;
    customNoRanksIcon?: ReactNode;
    progressExitButton?: ReactNode;
    preInitCallback?: () => Promise<{
      userid: string;
      name?: string;
      profile_pic?: string;
    }> | void;
    postInitCallback?: Function;
    prePaymentConfirmationCallback?: (tournament: ITournament) => void;
    postPaymentConfirmationCallback?: (
      // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
      payload: any & { reference: string; amount: number; initiated: boolean },
    ) => void;
    postPaymentShowVerifyModal?: boolean;
    externalMessageHandler?: Function;
    shareHandler?: (text: string, score?: number, url?: string, tournamentId?: number) => any;
    copyToClipboard?: (copyText: string) => any;
    paymentButtonText?: string;
    footerIcons?: {
      browseGames?: any;
      myGames?: any;
      arcade?: any;
      heroes?: any;
      missions?: any;
    };
    paymentFlow?: {
      token?: {
        getTokens: Function;
        purchaseTokens: Function;
        consumeTokens: Function;
        getTransactions: Function;
      };
    };
    instance?: any;
    requestBalance?: Function;
    isRazerGuestUser?: Function;
    getTokenStatusBar?: Function;
    getTicketStatusBar?: Function;
    requestCortexUserAuth?: Function;
    getPhoneNumber?: Function;
    handlePayment?: (t: ITournament) => void;
    onTournamentGameStart?: () => void;
    onTournamentGameEnd?: () => void;
    onArcadeGameStart?: () => void;
    onArcadeGameEnd?: () => void;
    handleNotificationToggle?: (value: boolean) => void;
    handleCtaButtonClick?: (url: string) => void;
    shareToFeed?: Function;
    shareGameOverDetails?: Function;
    navigateToLink?: Function;
    shareTournamentWin?: Function;
    monopoly?: any;
    login?: Function | null;
  }
}

export const DEFAULT_KEEP_ALIVE_INTERVAL = 30000;
const MINIPROGRAM_SDK_LINK = "https://appx/web-view.min.js";

function miniProgramMessageHandler(data: any) {
  const resultCode = data?.payload?.resultCode;
  if (["6001", "4000"].includes(resultCode)) {
    const apiPayload = {
      reference: data?.payload?.paymentData?.reference,
      status: "cancel",
      failed_reason: "Payment was cancelled",
    };
    if (resultCode === "4000") {
      apiPayload.status = "failed";
      apiPayload.failed_reason = "Payment Failed!";
    }
    store.dispatch(changeMiniProgramPaymentVerifyModalStatus("cancelling"));
    api
      .post("payments/cancel-payment/", apiPayload)
      .then(() => store.dispatch(changeMiniProgramPaymentVerifyModalStatus("cancelled")))
      .catch(() => store.dispatch(changeMiniProgramPaymentVerifyModalStatus("error")));
  } else {
    store.dispatch(changeMiniProgramPaymentVerifyModalStatus("checking"));
  }
}

function miniProgramPostInitHandler(user: IUser) {
  const username = user?.username || "";
  setTimeout(() => {
    if (window.my) {
      window.my.postMessage({
        type: "SET_USERID",
        payload: username,
      });
      window.my.onMessage = miniProgramMessageHandler;
    } else {
      toast(`SDK did not load correctly. Some features might not work as expected. ${window.my}`, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
      /*  alert(
        `SDK did not load correctly. Some features might not work as expected. ${window.my}`
      ); */
    }
  }, 3500);
}

function miniProgramShareToFeedHandler(score?: number, game?: IGame) {
  const gameName = game?.name;
  if (window.my) {
    window?.my?.postMessage({
      type: "SHARE_TO_FEED",
      payload: { score, gameName },
    });
  } else {
    toast(`SDK did not load correctly. Share to feed might not work as expected. ${window.my}`, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
    /* alert(
      `SDK did not load correctly. Some features might not work as expected. ${window.my}`
    ); */
  }
}

function miniProgramMonopolyShareToFeedHandler({
  actor = "",
  prizeName = "",
  gameName = "",
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  redirectionUrl = "",
}) {
  // const gameName = game?.name;
  if (window.my) {
    window?.my?.postMessage({
      type: "DANAPOLY_SHARE_TO_FEED",
      payload: { actor, prizeName, gameName },
    });
  } else {
    toast(`SDK did not load correctly. Share to feed might not work as expected. ${window.my}`, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
    /* alert(
      `SDK did not load correctly. Some features might not work as expected. ${window.my}`
    ); */
  }
}

function miniProgramShareTournamentWinHandler(data: any) {
  if (window.my) {
    window?.my?.postMessage({
      type: "MINIPROGRAM_AUTOSHARE",
      payload: { ...data },
    });
  } else {
    toast(`SDK did not load correctly. MINIPROGRAM_AUTOSHARE might not work as expected. ${window.my}`, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
    /*  alert(
      `SDK did not load correctly. MINIPROGRAM_AUTOSHARE might not work as expected. ${window.my}`
    ); */
  }
}

function miniProgramNavigateToLinkHandler(url: string) {
  if (window.my) {
    window?.my?.postMessage({
      type: "NAVIGATE_TO_LINK",
      payload: {
        targetLink: url,
      },
    });
  } else {
    toast(`SDK did not load correctly. Navigate to link might not work as expected. ${window.my}`, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
    /* alert(
      `SDK (NAVIGATE_TO_LINK) did not load correctly. Some features might not work as expected. ${window.my}`
    ); */
  }
}

function miniProgramPostPaymentConfirmationHandler(payload: any) {
  if (window.my) {
    window?.my?.postMessage({ type: "CONFIRM_PAYMENT", payload });
  } else {
    toast(`SDK did not load correctly. Payment might not work as expected. ${window.my}`, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
    /* alert(
      `SDK did not load correctly. Some features might not work as expected. ${window.my}`
    ); */
  }
}

const commonMiniAppSdk: ISdkResource = {
  type: "external-script",
  resourceLink: MINIPROGRAM_SDK_LINK,
  goBackHome: () => {},
  postInitCallback: miniProgramPostInitHandler,
  postPaymentConfirmationCallback: miniProgramPostPaymentConfirmationHandler,
};

export const sdkResources: Record<string, ISdkResource> = {
  default: {
    type: "built-in",
    goBackHome: null,
  },
  [`${APP_SLUGS.OOREDOO}`]: {
    type: "built-in",
    goBackHome: () => {
      try {
        window?.jsInterface?.exec("moa", "back");
      } catch (e) {
        console.log(e);
      }
    },
  },
  rappi: {
    type: "built-in",
    footerIcons: {
      browseGames: BrowseIcon,
      myGames: MyGameIcon,
      arcade: ArcadeIcon,
      heroes: CrownIcon,
    },
    goBackHome: () => {
      if (window.app !== undefined) {
        window.app.endCall();
      }
      if (window.appInterface !== undefined) {
        window.appInterface.endCall();
      }
      if (window.webkit !== undefined) {
        window.webkit.messageHandlers.endCall.postMessage("");
      }
    },
    paymentFlow: {
      token: {
        // token modal
        getTokens: () => {
          if (window.app) return window.app.getTokens();
          if (window.appInterface) return window.appInterface.getTokens();
          if (window.webkit) return window.webkit.messageHandlers.endCall.getTokens("");
        },
        consumeTokens: (amount: number, reference: string, callback: Function) => {
          //parchase successful
          // if (window.app) return window.app.consumeTokens(amount, reference);
          // if (window.appInterface)
          //   return window.appInterface.consumeTokens(amount, reference);
          // if (window.webkit)
          //   return window.webkit.messageHandlers.endCall.consumeTokens(
          //     amount,
          //     reference
          //   );
          // return false;
          setTimeout(function () {
            callback();
          }, 2000);
        },
        purchaseTokens: (amount: number, callback: Function) => {
          //don't have enough token
          // The callback function will handle the message after successful purchase
          // TODO: use callback to handle the message
          // if (window.app) return window.app.purchaseTokens(amount);
          // if (window.appInterface)
          //   return window.appInterface.purchaseTokens(amount);
          // if (window.webkit)
          //   return window.webkit.messageHandlers.endCall.purchaseTokens(amount);
          // return false;

          setTimeout(function () {
            callback();
          }, 2000);
        },
        getTransactions: (): any[] => {
          //token history
          if (window.app) return window.app.getTransactions();
          if (window.appInterface) return window.appInterface.getTransactions();
          if (window.webkit) return window.webkit.messageHandlers.endCall.getTransactions();
          return [];
        },
      },
    },
  },

  pepsico: {
    type: "built-in",
    goBackHome: () => {
      const data = { event: "end" };
      const stringify = JSON.stringify(data);
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(stringify);
      }
    },
  },

  nham24: {
    type: "built-in",
    goBackHome: () => {
      if (window.nham24 !== undefined) {
        window.nham24.endGame();
      }
    },
  },

  foodpandaapp: {
    type: "built-in",
    goBackHome: () => {
      if (window.webkit) {
        window?.webkit?.messageHandlers?.callbackHandler?.postMessage("onBackPressed");
      } else if (window.Android) {
        window.Android.onBackPressed();
      }
    },
  },

  bkash: bKashSdk,
  klook: klookSdk,

  sabay: {
    type: "package",
    goBackHome: () => {},
    instance: new MS.Service(new MS.Configure(import.meta.env.VITE_MY_SABAY_APPLICATION_ID, isInDevelopmentMode())),
  },

  jazzcash: jazzcashSdk,
  gcash: {
    ...commonMiniAppSdk,
    navigateToLink: miniProgramNavigateToLinkHandler,
  },
  gcashaliplus: commonMiniAppSdk,
  "gcashaliplus-test": commonMiniAppSdk,
  tngaliplus: commonMiniAppSdk,
  danaaliplus: {
    ...commonMiniAppSdk,
    shareToFeed: miniProgramShareToFeedHandler,
  },
  [`${APP_SLUGS.WOW_GAMES}`]: commonMiniAppSdk,

  tng: commonMiniAppSdk,
  /* aziza: {
    type: "external-script",
    goBackHome: () => {}
  }, */
  dana: {
    ...commonMiniAppSdk,
    shareToFeed: miniProgramShareToFeedHandler,
    monopoly: {
      shareToFeed: miniProgramMonopolyShareToFeedHandler,
    },
    shareTournamentWin: (data: any) => {
      const params = {
        tournament: data?.title,
        game: data?.game,
        score: data?.score,
        highScore: data?.score,
        rank: data?.rank || null,
      };
      miniProgramShareTournamentWinHandler({ ...params });
      /* if(!params.rank) {
        store
        .dispatch(fetchUserRanking(data?.tournament?.id) as any)
        .then((response: any) => {
          params.rank = response?.rank;
        })
        .finally(() => {
          miniProgramShareGameOverDetailsHandler({ ...params });
        });
      } else {
        miniProgramShareGameOverDetailsHandler({ ...params });
      } */
    },
  },

  "alipay-hk": {
    ...commonMiniAppSdk,
    handleCtaButtonClick: (url: string) => {
      try {
        window.my.call("pushWindow", { url });
      } catch (e) {
        console.error(e);
      }
    },
  },

  daviplata: {
    type: "built-in",
    goBackHome: () => {},
    preInitCallback: async () => {
      // Create a promise that resolves when the callback is invoked
      let initViewResolve: (userData: any) => void;
      const initViewPromise = new Promise<any>(resolve => {
        initViewResolve = resolve;
      });

      // Define the initView callback
      window.initView = function (userData: any) {
        RollbarClient.error("DAVIPLATA SUCCESS", { userData });
        initViewResolve(userData);
      };

      // Set a timeout for waiting
      const timeoutDuration = 30000; // 30 seconds
      const timeoutPromise = new Promise<any>((_, reject) => {
        setTimeout(() => {
          RollbarClient.error("DAVIPLATA FAILURE", "initView callback not called within the timeout 30s.");
          reject(new Error("initView callback not called within the timeout 30s."));
        }, timeoutDuration);
      });

      try {
        // Wait for the initViewPromise to resolve or timeout
        const userData = await Promise.race([initViewPromise, timeoutPromise]);

        // Construct and return the result object
        return {
          // userid: JSON.stringify(userData, null, 2),
          userid: userData,
        };
      } catch (error) {
        // Handle timeout or other errors
        console.error(error);
        return { userid: "" }; // Return a default value or handle the error case appropriately
      }
    },
  },

  "bts-digital": {
    type: "built-in",
    goBackHome: () => {},
    preInitCallback: async () => {
      let user: any;
      try {
        const { default: aituBridge } = await import("@btsd/aitu-bridge");
        const timeout = new Promise((_, reject) =>
          setTimeout(() => reject(new Error("Operation timed out after 30s")), 30000),
        );

        // Race the timeout promise against the aituBridge.getMe() call
        user = await Promise.race([aituBridge.getMe(), timeout]);
        // handle data
      } catch (e) {
        console.log({ e });
        // handle error
        RollbarClient.error("BTS_DIGITAL FAILED FETCH USER FROM AITUBRIDGE", { e });
      }
      return {
        userid: user?.id,
        name: user?.name,
        profile_pic: user?.avatar || `https://${window.location.host}/man.png`,
      };
    },

    postInitCallback: async (user: IUser) => {
      const aituBridge = await import("@btsd/aitu-bridge");
      if (!user.disable_notification) {
        aituBridge.default.enableNotifications();
      } else {
        aituBridge.default.disableNotifications();
      }
    },

    handleNotificationToggle: value => {
      (async () => {
        const aituBridge = await import("@btsd/aitu-bridge");
        if (value === false) {
          aituBridge.default.enableNotifications();
        } else {
          aituBridge.default.disableNotifications();
        }
      })();
    },

    shareHandler: async (text: string, score?: number, url?: string, tournamentId?: number) => {
      const aituBridge = await import("@btsd/aitu-bridge");
      let shareUrl: string;
      if (url) {
        shareUrl = url;
        if (tournamentId) {
          shareUrl += `?navigateToTournament=${tournamentId}`;
        }
      } else {
        shareUrl = "";
      }
      const result = await aituBridge.default.share(`${text} ${shareUrl}`);
      api
        .get(`statuspoints/mission/socialshare/ticket/bts-digital/`)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .then(response => {
          // console.log(response.data);
        })
        .catch(() => {
          // console.log("Error")
        });
      return result;
    },

    copyToClipboard: async (copyText: string) => {
      const aituBridge = await import("@btsd/aitu-bridge");
      try {
        await aituBridge.default.copyToClipboard(copyText);
      } catch (e) {
        console.error(e);
      }
    },

    getPhoneNumber: async () => {
      const aituBridge = await import("@btsd/aitu-bridge");
      try {
        const phone = await aituBridge.default.getPhone();
        return phone;
      } catch (e) {
        console.error(e);
        return null;
      }
    },
  },

  ridmik: ridmikSdk,

  razer: {
    type: "built-in",
    goBackHome: () => {
      if (window.Cortex) {
        window.Cortex?.dismissWebView();
      }
    },
    handlePayment: handleRazerPayment,
    requestBalance: getCortexAccountInfo,
    postInitCallback: () => {
      getCortexUserAuth();
      getCortexAccountInfo();
    },
    isRazerGuestUser: isRazerGuestUser,
    getTokenStatusBar: () => {
      return <RazerGoldStatus />;
    },
    getTicketStatusBar: () => {
      return <RazerSilverStatus />;
    },
    shareHandler: handleCortexSocialShare,
    onTournamentGameStart: () => {
      handleCortexAnalyticsEvent("tournament-entered");
      hideCortexAd();
    },
    onTournamentGameEnd: () => {
      showCortexAd();
    },
  },
};
