import React from "react";
import { css } from "@emotion/react";
import { Trans } from "react-i18next";
import { CommonModal } from "../../Modals/CommonModal";

import { ReactComponent as RightArrow } from "assets/images/ooredoo/arrow-right.svg";

import Ooredoo from "assets/images/ooredoo/ooredoo.png";
import Mpt from "assets/images/ooredoo/m-pitesan.png";
import EntryFee from "components/common/EntryFee";
import MediumLoadingIndicator from "components/common/LoadingIndicator/MediumLoadingIndicator";

const PAYMENT_TYPE = { ooredooAccount: "ooredoo_account", mpitesan: "mpitesan" };

export default function OoredooPaymentTypeModal({ show, onHide, tournament, onPayment, loading }: any) {
  const handlePayment = (type: string) => {
    !loading && onPayment({ tournament, extraData: { payment_type: type } });
  };

  return (
    <>
      <CommonModal
        loading={loading}
        show={show}
        onHide={onHide}
        style={ooredooPaymentModalStyle}
        header={
          !loading && (
            <div>
              <div className="d-flex justify-content-center">
                <div className="pay">
                  <Trans i18nKey="pay">Pay &nbsp;</Trans>{" "}
                </div>
                <div className="currency-color">
                  <EntryFee
                    entry_fee={tournament?.entry_fee}
                    payMethod={tournament?.entry_fee_type}
                    tournamentFeeCurrency={tournament?.entry_fee_currency}
                  />
                </div>
              </div>

              <span>
                <Trans i18nKey="select-your-pay">Select your Payment option</Trans>
              </span>
            </div>
          )
        }
      >
        {loading ? (
          <div className={"d-flex justify-content-center"}>
            <MediumLoadingIndicator size={2} color="#c92121" />
          </div>
        ) : (
          <div>
            <div
              className="d-flex flex-row justify-content-between card-slide py-4 px-3"
              onClick={() => {
                handlePayment(PAYMENT_TYPE.ooredooAccount);
              }}
            >
              <div className="d-flex align-items-center me-2">
                <img className="ooredoo-account" alt="logo" src={Ooredoo} />
              </div>
              <div className="ooredoo-account-text align-items-center">
                <div className="header-text">
                  <Trans i18nKey="ooredoo-account">Ooredoo Account</Trans>
                </div>
                <div className="subtitle-text">
                  <Trans i18nKey="pay-from-ooredo">Pay from Ooredoo account balance</Trans>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <RightArrow className="right-arrow" />
              </div>
            </div>

            <div
              className="d-flex flex-row justify-content-between card-slide py-4 px-3 mt-4"
              onClick={() => {
                handlePayment(PAYMENT_TYPE.mpitesan);
              }}
            >
              <div className="d-flex align-items-center me-2">
                <img className="ooredoo-account" alt="logo" src={Mpt} />
              </div>
              <div className="ooredoo-account-text align-items-center">
                <div className="header-text">
                  <Trans i18nKey="mpitesan">Mpitesan</Trans>
                </div>
                <div className="subtitle-text">
                  <Trans i18nKey="pay-from-mpites">Pay from Mpitesan account balance</Trans>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <RightArrow className="right-arrow" />
              </div>
            </div>
          </div>
        )}
      </CommonModal>

      <div id="mpitesan-form" style={{ display: "none" }} />
    </>
  );
}

const ooredooPaymentModalStyle = css`
  .card-slide {
    border: 1px solid #dfdfdf;
    border-radius: 10px;
    box-shadow: -2px 5px 12px rgb(80 80 80 / 15%);
  }

  .header_title {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
  }

  .currency-color {
    color: #c94030;
    display: flex;
    align-items: center;
    font-size: 20px;
  }

  .header-text {
    font-weight: 600;
    font-size: 16px;
  }

  .subtitle-text {
    color: #848484;
    font-size: 13px;
  }

  .ooredoo-account {
    width: auto;
    height: 50px;
  }

  .right-arrow {
    fill: #848484;
    width: 20px;
    height: 20px;
  }

  .pay {
    font-size: 20px;
  }
`;
