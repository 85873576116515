import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
// import tournamentsAPI from "actions/api/tournaments";
// import { setProfilePic } from "actions/fetchCommonData";
import { lighten, rgba } from "polished";

import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "redux/Store";
import { setUserTeam } from "redux/reducers/teamReducer";
import SelectTeam from "components/feature-specific/teams/SelectTeam";
import api from "actions/api";
import { setProfilePic } from "redux/reducers/commonReducer";

const congratsModalStyle = (theme: ITheme) => css`
  z-index: 100004 !important;
  .gift-icon {
    width: 215px;
    position: absolute;
    top: -123px;

    @media (min-width: 1022px) {
      width: 250px;
    }
  }

  .modal-content {
    border-radius: 15px;
    width: 95%;
    border: none;

    @media (min-width: 1022px) {
      width: 70%;
    }
  }

  .close {
    font-size: 35px;
    padding-top: 5px;
    font-weight: 300;

    &:focus {
      outline: none;
    }
  }

  .modal-header {
    border: none;
  }

  .congrats {
    font-size: 25px;
    font-weight: 600;
    color: black;
  }

  .first-para {
    padding: 15px 0;
    font-weight: 500;
    font-size: 17px;
  }

  .second-para {
    padding: 15px 0;
    font-weight: 500;
    font-size: 17px;
  }

  .btn {
    background: red;
    border-radius: 10px;
    font-weight: 600;
    color: ${theme.foreground.primary};
    box-shadow: 0px 13px 8px 1px ${lighten(0.3, theme.button.primaryGradientStart)};
  }

  .email-input-css {
    display: block;
    width: 100%;
    border: none;
    border-radius: 10px;
    background: #f9f9f8 !important;
    margin-bottom: 20px;
    padding: 5px 7px !important;
    height: calc(1.7em + 0.9rem + 3px);
    font-size: 14px;
    font-weight: 600;

    &:focus {
      outline: none;
    }
  }

  .submit-btn {
    color: white;
    background: ${theme.button.primaryGradientStart};
    margin-bottom: 15px;
    padding: 8px 45px;
    border-radius: 10px;
    border: none;
    font-size: 15px;
    font-weight: 600;
    box-shadow: 2px 10px 10px 0px ${rgba(theme.button.primaryGradientStart, 0.25)};

    &:focus {
      outline: none;
    }
  }

  .close {
    visibility: hidden;
  }
`;

export default function TeamSelectionModal() {
  const form = useForm();
  const { register, errors, handleSubmit } = form;
  const { application, user } = useAppSelector((state: any) => state.common);
  const { t } = useTranslation("translation", { useSuspense: false });
  const [show, setShow] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    api
      .get(`tournaments/team-based-application/teams/${application?.slug}/check/`)
      .then(response => {
        if (response.data) return setShow(false);
      })
      .catch(e => {
        if (e.response.status === 404) return setShow(true);
        else console.error(e);
      });
  }, [user, application]);

  const memberRegistration = (data: any) => {
    const { email, team } = data;
    const member = user?.id;

    const params: any = {
      member,
    };

    params["team"] = team;
    params["email"] = email;

    api
      .post(`tournaments/team-based-application/teams/name/create/`, params)
      .then(response => {
        api
          .get(`tournaments/team-based-application/teams/${application?.slug}/check/`)
          .then(response => {
            if (response.data) {
              if (response?.data?.memmber_of) {
                dispatch(setUserTeam(response?.data?.memmber_of));
                dispatch(setProfilePic(response?.data?.memmber_of?.team_logo));
              } else {
                dispatch(setUserTeam(null));
              }
            }
          })
          .catch(() => {
            dispatch(setUserTeam(null));
          });
        if (response) return setShow(false);
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .catch((error: any) => {
        // console.log(error)
      });
  };

  // const handleShow = () => setShow(false);
  return (
    <Modal
      show={show}
      // onHide={handleShow}
      size="lg"
      centered
      aria-labelledby="contained-modal-title-vcenter"
      style={{ zIndex: 100000 }}
      css={congratsModalStyle}
      className="desktop-modal p-0"
      backdrop="static"
    >
      <Modal.Header closeButton className="pb-0" />
      <Modal.Body className="pt-0">
        <form onSubmit={handleSubmit(memberRegistration)} action="search.php" method="post">
          <SelectTeam form={form} checkSignupPage={false} />
          {(errors?.team && (
            <p className="mb-0 text-danger pb-3">
              <small>
                <b>{errors?.team?.message}</b>
              </small>
            </p>
          )) || <br />}

          <input
            name="email"
            type="text"
            className="email-input-css ps-2"
            placeholder={t("email@address.c", {
              defaultValue: "email@address.com",
            })}
            ref={register({
              required: {
                value: true,
                message: t("required!", { defaultValue: "Required!" }),
              },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: t("invalid-email-a", {
                  defaultValue: "Invalid email address!",
                }),
              },
            })}
          />

          <div className="w-100 text-center">
            <button className="submit-btn">Submit</button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}
