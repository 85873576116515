import { css } from "@emotion/react";
import ANTARIO_REGULAR from "assets/fonts/Antario/Antario-Regular.otf";
import ANTARIO_BOLD from "assets/fonts/Antario/Antario-Bold.otf";
import ArcorBgImage from "assets/images/arcor/arcor_bg_image.png";

const arcorStyles = css`
  @font-face {
    font-family: "Antario";
    src: local("Antario"), url(${ANTARIO_REGULAR}) format("opentype");
  }

  @font-face {
    font-family: "AntarioBold";
    src: local("AntarioBold"), url(${ANTARIO_BOLD}) format("opentype");
  }

  &.arcor {
    * {
      font-family: "Antario", "AntarioBold";
    }

    .recommended-games-container {
      .recommended-games {
        .title-md {
          color: #004c92e6;
        }
      }
    }

    body {
      background-image: url(${ArcorBgImage});
      background-attachment: fixed;
      background-size: contain;
    }

    .header-color,
    .background-sty,
    .arcor-game-page,
    .my-games-page .header-style {
      background-color: transparent;
    }
    .userprofile-background {
      background-color: #ffc027;
    }

    .terms-condition-page {
      background: transparent;
    }

    .top-bar {
      background: transparent !important;
    }
    .icon-container {
      .label-sty {
        color: #000000;
      }
      input {
        color: #ffffff;
        ::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: #fff;
        }
        :-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: #fff;
        }
        ::-ms-input-placeholder {
          /* Microsoft Edge */
          color: #fff;
        }
      }
      .icon-right {
        path {
          fill: #ffffff;
        }
      }
    }
    .history-border {
      color: #000000;
    }
    .sidebar-container {
      .active-link {
        .link-img {
          fill: #ffffff;
        }
      }
    }
    .browse-game-item {
      .labelbold-style {
        color: #000000;
      }
    }

    .text-upper-style {
      .dont-account {
        color: #ffffff !important;
      }
    }

    .card-prize {
      .card-body {
        color: #000000 !important;
      }
    }

    .footer-wrapper {
      .logo {
        filter: brightness(0) invert(1);
      }
    }

    @media (max-width: 1021px) {
      #profile-page {
        background-color: transparent;
      }

      .navbar-logos {
        img {
          max-width: 90%;
          margin-right: auto;
          margin-left: 10px;
        }
      }
    }
  }
`;

export default arcorStyles;
