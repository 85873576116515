import { css } from "@emotion/react";
import { APP_SLUGS } from "constants/constants";

export const wowGamesStyles = css`
  &.${APP_SLUGS.WOW_GAMES} {
    .random-games {
      &.btn {
        display: none;
      }
    }
    .mobile-footer-page {
      display: none;
    }
    .profile-history-list {
      .prize-title,
      .prize-value {
        display: none;
      }
    }
    .history-container {
      .entry-fee {
        .label,
        .label-value,
        .tournament-type {
          display: none;
        }
      }
    }
  }
`;

export default wowGamesStyles;
